/* eslint no-underscore-dangle: 0 */ // --> OFF
import React, { useState } from 'react';
import { useTheme } from 'emotion-theming';
import {
  Popper, Fade, Paper,
} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import moment from 'moment';
import { rem } from 'polished';
import Link from 'next/link';
import { get } from 'lodash';
import {
  CALENDAR_BORDER_WIDTH,
  MAX_LEAVES_TO_SHOW,
  CALENDAR_DAY_PADDING,
  DAY_FORMAT, CALENDAR_LARGE_DAY_PADDING, FIXED_DAY_HEIGHT, CALENDAR_LARGE_DAY_TOP_OFFSET,
} from '../PauseFullCalendar/constants';
import { LEAVE_HEIGHT, PauseFullCalendarLeave } from '../PauseFullCalendarLeave';
import { PauseFullCalendarDayOuter } from './components';
import { PauseTextButton } from '../../PauseTextButton';
import {
  LEAVE_APPLICATION_JOURNEY_KEY,
  LEAVE_APPLICATION_JOURNEY_NEW_APPLICATION_KEY,
  useEnhancedRouter,
} from '../../../../router';
import { LeaveType } from '../PauseFullCalendar/utils';

const useGetLeavePositionStyles = () => {
  const theme = useTheme<any>();
  let paddingCount = 0;
  let borderCount = 0;
  return {
    getLeaveStylesForNormalDay: (leave) => {
      const styles = {
        width: '',
        marginLeft: '',
        gridRow: '',
      };

      if (leave._isOneFullDay) styles.width = '100%';

      if (leave._totalDays % 1 !== 0) {
        paddingCount = Math.floor(leave._totalDays) * 2;
      } else if (leave._startsSecondHalf) {
        paddingCount = leave._totalDays * 2;
      } else {
        paddingCount = (leave._totalDays - 1) * 2;
      }

      if (leave._extendsToNextWeek) {
        paddingCount += 1;
      }

      borderCount = Math.floor(paddingCount / 2);

      const paddingBasedExtraWidth = paddingCount * theme.spacing(CALENDAR_DAY_PADDING);
      const borderBasedExtraWidth = borderCount * theme.spacing(CALENDAR_BORDER_WIDTH);

      // Width needs to be a minimum of 100% even when its a half day
      // eslint-disable-next-line no-nested-ternary
      const daysCount = leave._totalDays < 1 ? 1 : leave._totalDays;
      styles.width = `calc((${daysCount} * 100%) + (${paddingBasedExtraWidth}px) + (${borderBasedExtraWidth}px))`;

      styles.gridRow = `${leave._position} / span 1`;
      // Only full day leaves need a margin because half days are taken care of by offsetting
      // the internal pseudo element -- because the name needs to overflow in the case of a half
      // day but not in the case of a multi day
      if (!leave._isHalf && leave._startsSecondHalf && !leave._extendedFromPrevWeek) {
        styles.marginLeft = 'calc(50%)';
      }

      return styles;
    },
    getLeaveStylesForSeeAllDay: (leave) => {
      let extraWidthCounter = 0;
      if (leave.isCutOffOnLeft) extraWidthCounter += 1;
      if (leave.isCutOffOnRight) extraWidthCounter += 1;
      return ({
        width: `calc(100% + (${extraWidthCounter} * ${theme.spacing(CALENDAR_LARGE_DAY_PADDING)}px))`,
        gridRow: `${leave._position} / span 1`,
        ...((!leave._isHalf && leave._startsSecondHalf && !leave._extendedFromPrevWeek) && {
          marginLeft: 'calc(50%)',
        }),
      });
    },
  };
};

const PauseFullCalendarDayContent = ({
  leaves = [],
  onSeeMore,
  enforceShowMoreButton,
}: {
    leaves: Array<LeaveType & {
      _isMain: boolean,
      _position: number
  }>,
  onSeeMore: () => any,
  enforceShowMoreButton: boolean
}) => {
  // eslint-disable-next-line no-nested-ternary
  const noOfVisibleLeaves = enforceShowMoreButton ? MAX_LEAVES_TO_SHOW - 1 : (leaves.length
    < MAX_LEAVES_TO_SHOW + 1 ? MAX_LEAVES_TO_SHOW : MAX_LEAVES_TO_SHOW - 1);

  const noOfHiddenLeaves = leaves.filter((l) => l._position > noOfVisibleLeaves).length;

  const { getLeaveStylesForNormalDay } = useGetLeavePositionStyles();

  return (
    <div css={(theme) => ({
      display: 'grid',
      gridGap: theme.spacing(1),
      marginTop: theme.spacing(1),
      gridTemplateColumns: 'minmax(0, 1fr)',
      gridTemplateRows: `repeat(${noOfVisibleLeaves}, ${theme.spacing(LEAVE_HEIGHT)}px)`,
    })}
    >
      {leaves.filter(
        (leave) => leave._position <= noOfVisibleLeaves && (leave._isMain),
      ).map((leave: LeaveType & {
        _isMain: boolean,
        _position: number
      }) => (
        (
          <React.Fragment key={`${leave.id}`}>
            <Link href={leave.href || '/'} scroll={false}>
              <div
                css={{
                  ...getLeaveStylesForNormalDay(leave),
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                }}
              >
                <PauseFullCalendarLeave
                  // @ts-ignore
                  isHalf={leave._isHalf}
                  startsSecondHalf={leave._startsSecondHalf}
                  isCutOffOnRight={leave._extendsToNextWeek}
                  alignRight={leave._isHalf && leave._startsSecondHalf}
                  // @ts-ignore
                  person={leave.applicant}
                  needsAction={get(leave, 'status') === 'PENDING'}
                />
              </div>
            </Link>
          </React.Fragment>
        )
      ))}
      {noOfHiddenLeaves > 0 && (
      <div
        css={{
          gridRow: `${MAX_LEAVES_TO_SHOW} / span 1`,
          justifyContent: 'left',
          justifySelf: 'start',
        }}
      >
        <PauseTextButton
          onClick={(e) => {
            e.stopPropagation();
            onSeeMore();
          }}
          compact
          size="small"
        >
          {noOfHiddenLeaves}
          {' '}
          More
        </PauseTextButton>
      </div>
      )}

    </div>
  );
};

const PauseFullCalendarSeeAllDayContent = ({
  leaves = [],
  date: DATE,
}: {
    leaves: Array<LeaveType>,
    date: string
}) => {
  // eslint-disable-next-line no-nested-ternary
  const { getLeaveStylesForSeeAllDay } = useGetLeavePositionStyles();
  return (
    <div css={(theme) => ({
      display: 'grid',
      gridGap: theme.spacing(1),
      marginTop: theme.spacing(1),
      gridTemplateColumns: 'minmax(0, 1fr)',
      gridTemplateRows: `repeat(${leaves.length}, ${theme.spacing(LEAVE_HEIGHT)}px)`,
    })}
    >
      {leaves.map((leave) => {
        const leaveWithContextualKeys = {
          ...leave,
          isCutOffOnLeft: moment(leave.startDate).format(DAY_FORMAT) !== moment(DATE).format(DAY_FORMAT),
          isCutOffOnRight: moment(leave.endDate).format(DAY_FORMAT) !== moment(DATE).format(DAY_FORMAT),
        };
        return (
          <Link key={leave.id} href={leave.href || '/'} scroll={false}>
            <div
              css={{
                ...getLeaveStylesForSeeAllDay(leaveWithContextualKeys),
              }}
            >
              <PauseFullCalendarLeave
                // @ts-ignore
                isHalf={leave._isHalf}
                extraPaddingForCutoff={CALENDAR_LARGE_DAY_PADDING}
                startsSecondHalf={leave._startsSecondHalf}
                isCutOffOnRight={leaveWithContextualKeys.isCutOffOnRight}
                isCutOffOnLeft={leaveWithContextualKeys.isCutOffOnLeft}
                alignRight={leave._isHalf && leave._startsSecondHalf}
                // @ts-ignore
                person={leave.applicant}
                needsAction={get(leave, 'status') === 'PENDING'}
              />
            </div>
          </Link>
        );
      })}

    </div>
  );
};

const PauseFullCalendarDaySeeAll = ({
  onClose,
  leaves,
  anchorEl,
  date: DATE,
  isActive,
  lockedFor,
  birthdays,
  publicHoliday,
  isNonWorkingDay,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <ClickAwayListener onClickAway={() => {
      setIsOpen(false);
      onClose();
    }}
    >
      <Popper
        open={isOpen}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        modifiers={{
          preventOverflow: {
            enabled: true,
          },
          flip: {
            enabled: false,
          },
        }}
      >
        {({ TransitionProps }) => (
          <Fade
            {...TransitionProps}
            timeout={350}
          >
            <Paper css={(theme) => ({
              // css={(theme) => ({
              width: anchorEl ? `calc(${anchorEl.clientWidth}px + (2 * ${theme.spacing(CALENDAR_LARGE_DAY_PADDING - CALENDAR_DAY_PADDING)}px))` : '100%',
              boxShadow: theme.shadows[3],
              marginLeft: -theme.spacing(CALENDAR_LARGE_DAY_PADDING - CALENDAR_DAY_PADDING),
              marginTop: `calc(0px - ${rem(FIXED_DAY_HEIGHT)} - ${theme.spacing(CALENDAR_LARGE_DAY_PADDING - CALENDAR_DAY_PADDING)}px - ${CALENDAR_LARGE_DAY_TOP_OFFSET}px)`,
              marginBottom: -theme.spacing(4),
              borderRadius: theme.spacing(2),
              overflow: 'hidden',
            })}
            >
              <PauseFullCalendarDayOuter
                date={DATE}
                isLarge
                lockedFor={lockedFor}
                birthdays={birthdays}
                publicHoliday={publicHoliday}
                isNonWorkingDay={isNonWorkingDay}
                isActive={isActive}
              >
                <PauseFullCalendarSeeAllDayContent
                  {...props}
                  date={DATE}
                  leaves={leaves}
                />
              </PauseFullCalendarDayOuter>

            </Paper>
          </Fade>
        )}
      </Popper>

      {/* // </div> */}
    </ClickAwayListener>
  // </div>
  );
};

type PauseFullCalendarDayProps = {
  isActive: boolean,
  date: moment.Moment,
  lockedFor?: Array<{
    endDate: string
    fullName: string
    reason: string | null
    startDate: string
  }>,
  birthdays?: Array<{
    date: string
    fullName: string
  }>,
  publicHoliday?: {
    calendarID: string
    date: string
    holidayApiID: string
    id: string
    name: string
    orgID: string
  },
  isNonWorkingDay?: boolean | {
    calendarID: string,
    date: string,
    holidayApiID: string,
    id: string,
    name: string,
    orgID: string,
  },
  leaves: Array<LeaveType & {
    _isMain: boolean,
    _position: number
  }>,
  enforceShowMoreButton: boolean,
  isNotInCurrentMonth: boolean,
}

export const PauseFullCalendarDay = React.memo<PauseFullCalendarDayProps>(({
  isActive = false,
  date: DATE,
  lockedFor = [],
  birthdays = [],
  publicHoliday = null,
  leaves = [],
  isNonWorkingDay,
  enforceShowMoreButton,
  isNotInCurrentMonth,
}) => {
  const [showingAll, setShowingAll] = useState(false);
  const anchorEl = React.useRef(null);
  const router = useEnhancedRouter();

  return (
    <div
      ref={anchorEl}
      css={() => ({
        opacity: isNotInCurrentMonth ? 1 : 1,
        transition: 'all 0.3s',
        cursor: 'pointer',
      })}
    >
      <PauseFullCalendarDayOuter
        date={DATE}
        lockedFor={lockedFor}
        birthdays={birthdays}
        publicHoliday={publicHoliday}
        isNonWorkingDay={isNonWorkingDay}
        isActive={isActive}
        onClick={() => {
          if ((window as any).seeMoreActivated) {
            return null;
          }
          return router
            .push(router
              .getStopHref(LEAVE_APPLICATION_JOURNEY_KEY,
                LEAVE_APPLICATION_JOURNEY_NEW_APPLICATION_KEY, {
                  type: moment(DATE)
                    .format(DAY_FORMAT),
                }));
        }}
      >
        <PauseFullCalendarDayContent {...{
          isActive,
          birthdays,
          lockedFor,
          publicHoliday,
          isNonWorkingDay,
          enforceShowMoreButton,
          date: DATE,
          leaves,
          onSeeMore: () => {
            (window as any).seeMoreActivated = true;
            setShowingAll(true);
          },
        }}
        />
      </PauseFullCalendarDayOuter>

      {showingAll && (
      <PauseFullCalendarDaySeeAll {...{
        isActive,
        birthdays,
        lockedFor,
        publicHoliday,
        isNonWorkingDay,
        date: DATE,
        anchorEl: anchorEl && anchorEl.current,
        leaves,
        onClose: () => {
          setShowingAll(false);
          setTimeout(() => {
            (window as any).seeMoreActivated = false;
          }, 0);
        },
      }}
      />
      )}
    </div>
  );
});
