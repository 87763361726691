import {
  useMemo,
  useEffect,
  useState,
  createRef,
  memo,
  useRef,
} from 'react';
import moment from 'moment';
import { FixedSizeList } from 'react-window';
import {
  get, debounce,
} from 'lodash';
import { PauseFullCalendarWeek } from '../PauseFullCalendarWeek';
import {
  FIXED_DAY_HEIGHT,
  CALENDAR_BORDER_WIDTH, MONTH_FORMAT, DAY_FORMAT,
} from './constants';
import { PauseTypography } from '../../PauseTypography';
import { getWeekForDate, groupLeavesByDayAndWeek, getAllWeeksBetweenMonths } from './utils';
import { Maybe } from '../../../types/onboarding';
import { ExpressiveColors } from '../../PauseTheme/colors';

const PauseFullCalendarWeekdays = () => (
  <div css={(theme) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gridGap: theme.spacing(CALENDAR_BORDER_WIDTH),
    color: theme.get('palette.pause.primary.light'),
  })}
  >
    {moment.weekdays().map(
      (weekday) => (
        <PauseTypography
          key={`${weekday}`}
          variant="caption"
          css={(theme) => ({
            padding: theme.spacing(0, 1, 1, 1),
          })}
        >
          {weekday.slice(0, 3)}
        </PauseTypography>
      ),
    )}
  </div>
);
type PauseFullCalendarProps = {
  earliestMonthToShow?: string,
  latestMonthToShow?: string,
  currentMonth?: string,
  nonWorkingDays?: Array<number>,
  onScroll?: (any?) => any,
  leaves?: Array<Leaves>,
  publicHolidays?: Array<PublicHolidays>,
  birthdays?: Array<Birthdays>,
  lockedDates?: Array<LockedDates>,
  silentUpdate?: boolean
}

export type Leaves = {
  actioner: Maybe<
    {
    email: string,
    fullName: string,
    id: string,
    image: Maybe<string>,
    isAdmin: boolean,
    isArchived: boolean,
    teamID: string
    }
  >
  actionerID?: string
  applicant:{
    allowance: number,
    birthDate: moment.Moment,
    color: ExpressiveColors,
    email: string,
    fullName: string,
    id: string,
    image: string,
    isAdmin: boolean,
    isArchived: boolean,
    selected: boolean,
    teamID: string,
    teamName: string
  },
  applicantID: string,
  applicationReason: string,
  deduction: number,
  endDate: moment.Moment,
  endType: string
  href:{
    pathname: string,
    query: {
      id: string,
      journey: string,
      stop: string
    }
  },
  id: string,
  leaveType: {
    description: string,
    id: string
    name: string,
  }
  leaveTypeID: string,
  numberOfDaysCovered: number,
  rejectionReason: Maybe<string>,
  requester: {
    email: string,
    fullName: string,
    id: string,
    image?: string,
    isAdmin: boolean,
    isArchived: boolean,
    teamID: string
  },
  requesterID: string,
  startDate: moment.Moment,
  startType: string,
  status: string
}

export type PublicHolidays = {
  calendarID: string,
  date: string,
  holidayApiID: string,
  id: string
  name: string
  orgID: string
}

type Birthdays = {
  date: moment.Moment,
  fullName: string
}

type LockedDates = {
  endDate: string,
  fullName: string,
  reason: string | null
  startDate: string
}

export const PauseFullCalendar = memo<PauseFullCalendarProps>(({
  earliestMonthToShow = moment().subtract('12', 'months').format(MONTH_FORMAT),
  latestMonthToShow = moment().add('12', 'months').format(MONTH_FORMAT),
  currentMonth = moment().format(MONTH_FORMAT),
  nonWorkingDays = [1, 7],
  onScroll = () => {},
  leaves = [],
  publicHolidays = [],
  birthdays = [],
  lockedDates = [],
  silentUpdate,

}) => {
  const [height, setHeight] = useState<number>(0);

  // This is the index of the week that is in view (either calculated from
  // incoming `currentMonth` value or from the scroll event
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  // const prevIndex = usePrevious(currentIndex);

  type ListofWeeksProps = {
    startDate: moment.Moment,
    endDate: moment.Moment
  }

  const ref = useRef<Maybe<HTMLDivElement>>(null);
  const listRef = createRef<typeof FixedSizeList>();
  const listOfWeeks = useMemo<Array<ListofWeeksProps>>(
    () => getAllWeeksBetweenMonths({ earliestMonthToShow, latestMonthToShow }),
    [earliestMonthToShow, latestMonthToShow],
  );

  type GroupedLeavesByDayAndWeek = {
    [key: string]: any
  }

  const memoizedLeaves = useMemo<Array<Leaves>>(() => leaves, [leaves]);

  const groupedLeavesByDayAndWeek = useMemo<GroupedLeavesByDayAndWeek>(
    () => groupLeavesByDayAndWeek({ leaves: memoizedLeaves, listOfWeeks }),
    [leaves, listOfWeeks],
  );

  const startOfEarliestDay = moment(earliestMonthToShow, MONTH_FORMAT).startOf('month');
  const endOfLatestDay = moment(latestMonthToShow, MONTH_FORMAT).endOf('month');

  // Here we factor for incoming `currentMonth` values which fall outside of allowed values
  useEffect(() => {
    const startOfCurrentMonth = moment(currentMonth, MONTH_FORMAT).startOf('month');
    const endOfCurrentMonth = moment(currentMonth, MONTH_FORMAT).endOf('month');
    if (startOfCurrentMonth.isBefore(startOfEarliestDay)) {
      setCurrentIndex(0);
      return;
    }
    if (endOfCurrentMonth.isAfter(endOfLatestDay)) {
      setCurrentIndex(listOfWeeks.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMonth]);

  // Here we set index based on value of incoming `currentMonth`
  useEffect(() => {
    const startOfCurrentMonth = moment(currentMonth, MONTH_FORMAT).startOf('month');
    const weekInView = getWeekForDate({ listOfWeeks, date: startOfCurrentMonth });
    const indexOfWeekInView = weekInView && listOfWeeks.indexOf(weekInView);
    if (weekInView) {
      // @ts-ignore
      setCurrentIndex(indexOfWeekInView);
      // Here we imperatively scroll to the correct week when currentIndex changes
      if (listRef && listRef.current && !silentUpdate) {
        listRef.current.scrollToItem(indexOfWeekInView, 'start');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfWeeks, currentMonth]); // TODO - do not include any more dependencies here

  // This is run on mount
  useEffect(() => {
    setHeight(ref?.current?.clientHeight || 0);
  }, []);

  const debouncedOnScroll = debounce(({
    scrollOffset,
    scrollDirection,
    scrollUpdateWasRequested,
  }: {
      scrollOffset: number,
      scrollDirection: string,
      scrollUpdateWasRequested: boolean
  }) => {
    const index = Math.round(scrollOffset / FIXED_DAY_HEIGHT);
    const dateScrolledTo = get(listOfWeeks, `${scrollDirection === 'backward' ? index + 1 : index + 1}.endDate`);
    const formattedDateScrolledTo = dateScrolledTo.format(MONTH_FORMAT);
    if (formattedDateScrolledTo !== currentMonth && !scrollUpdateWasRequested) {
      onScroll(formattedDateScrolledTo);
    }
  }, 50);

  return (
    <div
      css={() => ({
        height: '100%',
        width: '100%',
      })}
      ref={ref}
    >
      <PauseFullCalendarWeekdays />
      {height > 0 && currentIndex > -1 && (
      <FixedSizeList
        ref={listRef}
        width="100%"
        height={height}
        itemCount={listOfWeeks.length}
        itemSize={FIXED_DAY_HEIGHT}
        initialScrollOffset={(currentIndex) * FIXED_DAY_HEIGHT}
        overscanCount={0}
        onScroll={debouncedOnScroll}
        css={() => ({
          'scrollbar-width': 'none', /* Firefox */
          '-ms-overflow-style': 'none', /* Internet Explorer 10+ */
          '&::-webkit-scrollbar': { /* WebKit */
            width: 0,
            height: 0,
          },
        })}
      >
        {({ index, style }) => {
          const { startDate, endDate } = listOfWeeks[index];
          return (
            <PauseFullCalendarWeek
              // @ts-ignore
              birthdays={birthdays}
              style={style}
              currentMonth={currentMonth}
              index={index}
              publicHolidays={publicHolidays}
              leaves={groupedLeavesByDayAndWeek[startDate.format(DAY_FORMAT)]}
              nonWorkingDays={nonWorkingDays}
              startDate={startDate}
              endDate={endDate}
              lockedDates={lockedDates}
            />
          );
        }}
      </FixedSizeList>
      )}
    </div>
  );
});
