import React from 'react';

export const PauseButtonGroup = ({ children }: {
  children: React.ReactElement[]
}) => (
  <div css={() => ({
    display: 'grid',
    alignItems: 'center',
    gridAutoFlow: 'column',
  })}
  >
    {React.Children.map(children, (child, index) => React.cloneElement(child, {
      ...(index === 0 && children.length > 1 && {
        isAttachedToRight: true,
      }),
      ...(index === children.length - 1 && children.length > 1 && {
        isAttachedToLeft: true,
      }),
      ...(index > 0 && index < children.length - 1 && children.length > 1 && {
        isAttachedToLeft: true,
        isAttachedToRight: true,
      }),
    }))}
  </div>
);
