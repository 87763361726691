import React, {
  useMemo, useState,
} from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { rem } from 'polished';
import { PauseTypography } from '../../PauseTypography';
import { getLockedCountDisplay, getPeopleCountDisplay } from '../../../../utils/strings';
import { Flag, Lock } from '../../../../assets/icons/feather/components';
import {
  CALENDAR_BORDER_WIDTH, CALENDAR_DAY_PADDING, CALENDAR_LARGE_DAY_PADDING, FIXED_DAY_HEIGHT,
  NON_WORKING_DAY_BACKGROUND,
} from '../PauseFullCalendar/constants';
import { PauseSimpleTooltip } from '../../PauseSimpleTooltip';
import { Maybe } from '../../../types/onboarding';

const getLabelFor = (a: any[] = [], b:any[] = [], c:any[] = []) => (labelKey = '') => {
  if (!b.length && !c.length) {
    if (a.length === 1) {
      return a[0][labelKey];
    }
    return `${a.length}`;
  }
  return '';
};
const GenericInfoContainer = ({
  children,
  onClick = () => null,
}: {
    children: React.ReactNode,
    onClick?: (any?) => any
}) => (
  <div
    css={{
      display: 'grid',
      gridAutoFlow: 'column',
      alignItems: 'center',
      width: '100%',
      gridGap: '4px',
      cursor: 'default',
    }}
    onClick={(e) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      onClick();
    }}
  >
    {children}
  </div>
);

const GenericInfoIconStyles = (theme) => ({
  color: theme.get('palette.pause.primary.medium'),
  width: theme.spacing(1.8),
  height: theme.spacing(1.8),
});

const isEllipsisActive = (e) => get(e, 'offsetWidth') < get(e, 'scrollWidth');

const GenericInfoText = ({
  children,
  title,
  color,
  enforceTooltip,
}: {
    children: React.ReactNode,
    title: NonNullable<React.ReactNode>,
    enforceTooltip: boolean,
    color?: string

}) => {
  const [ref, setRef] = useState<Maybe<HTMLElement>>(null);
  const showTooltip = useMemo(() => isEllipsisActive(ref), [ref]);

  const Content = (
    <PauseTypography
      ref={(r) => {
        setRef(r);
      }}
      css={(theme) => ({
        marginRight: theme.spacing(0.5),
      })}
      color={color || 'pause.primary.medium'}
      variant="caption"
      dontWrapText
    >
      {children}
    </PauseTypography>
  );
  return (enforceTooltip || showTooltip) ? (
    <PauseSimpleTooltip enterDelay={100} title={title}>
      {Content}
    </PauseSimpleTooltip>
  ) : Content;
};

export const ShowExtraInfo = ({
  lockedFor = [],
  birthdays = [],
  publicHolidays = [],
}: {
  lockedFor: any[],
  birthdays: any[],
  publicHolidays: any[]
}) => (
  <GenericInfoContainer>
    {lockedFor.length ? (
      <GenericInfoText
        title={(
          <div
            css={(t) => ({
              display: 'grid',
              gridAutoFlow: 'row',
              padding: t.spacing(0.5),
              gridGap: t.spacing(0.5),
            })}
          >
            <PauseTypography variant="caption">Locked for&mdash;</PauseTypography>
            <PauseTypography variant="body2">{lockedFor.map((l) => `${l.fullName} (${l.reason || 'No reason given'})`).join(', ')}</PauseTypography>
          </div>
      )}
        enforceTooltip
      >
        {getLabelFor(lockedFor, birthdays, publicHolidays)('fullName')}
        {' '}
        <Lock css={GenericInfoIconStyles} />
      </GenericInfoText>
    ) : null}
    {birthdays.length ? (
      <GenericInfoText
        title={(
          <div
            css={(t) => ({
              display: 'grid',
              gridAutoFlow: 'row',
              padding: t.spacing(0.5),
              gridGap: t.spacing(0.5),
            })}
          >
            <PauseTypography variant="caption">Birthdays&mdash;</PauseTypography>
            <PauseTypography variant="body2">{birthdays.map((b) => b.fullName).join(', ')}</PauseTypography>
          </div>
)}
        enforceTooltip
      >
        {getLabelFor(birthdays, lockedFor, publicHolidays)('fullName')}
        {' '}
        <span role="img" aria-label="cake">🍰</span>
      </GenericInfoText>
    ) : null}
    {publicHolidays.length ? (
      <GenericInfoText
        enforceTooltip
        color="pause.primary.medium"
        title={(
          <div
            css={(t) => ({
              display: 'grid',
              gridAutoFlow: 'row',
              padding: t.spacing(0.5),
              gridGap: t.spacing(0.5),
            })}
          >
            <PauseTypography variant="caption">Public holidays&mdash;</PauseTypography>
            <PauseTypography variant="body2">{publicHolidays.map((ph) => ph.name).join(', ')}</PauseTypography>
          </div>
      )}
      >
        {getLabelFor(publicHolidays, birthdays, lockedFor)('name')}
        {' '}
        <Flag css={GenericInfoIconStyles} />
      </GenericInfoText>
    ) : null}
  </GenericInfoContainer>
);

export const LockedDatesInfo = ({ lockedFor }: {
  lockedFor: Array<{
    endDate: string
    fullName: string
    reason: string | null
    startDate: string
  }>
}) => (
  <GenericInfoContainer>
    {lockedFor[0].fullName !== 'ALL' && (
      <GenericInfoText
        title={(
          <div
            css={(t) => ({
              display: 'grid',
              gridAutoFlow: 'row',
              padding: t.spacing(1),
              gridGap: t.spacing(0.5),
            })}
          >
            <PauseTypography variant="caption">Locked for&mdash;</PauseTypography>
            {lockedFor.map((l, idx) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`${l.fullName}-${idx}`}
                css={(t) => ({
                  marginTop: t.spacing(0.5),
                })}
              >
                <PauseTypography>{l.fullName}</PauseTypography>
                <PauseTypography variant="body2" color="pause.primary.light">
                  {l.reason || '-'}
                </PauseTypography>
              </div>
            ))}
          </div>
        )}
        enforceTooltip={lockedFor && lockedFor.length > 0}
      >
        {getLockedCountDisplay(lockedFor)}
      </GenericInfoText>
    )}
    <Lock css={GenericInfoIconStyles} />
  </GenericInfoContainer>
);

export const BirthdaysInfo = ({ birthdays = [] }: {
  birthdays: Array<{
    date: string
    fullName: string
  }>}) => (
    <GenericInfoContainer>
      <GenericInfoText title={birthdays.map((b) => b?.fullName).join(', ')} enforceTooltip={birthdays && birthdays.length > 1}>
        {getPeopleCountDisplay(birthdays)}
      </GenericInfoText>
      🍰
    </GenericInfoContainer>
);

export const PublicHolidayInfo = ({ publicHoliday }: {
  publicHoliday: {
    calendarID: string,
    date: string,
    holidayApiID: string,
    id: string,
    name: string,
    orgID: string,
  }
}) => (
  <GenericInfoContainer>
    <GenericInfoText enforceTooltip color="pause.primary.medium" title={publicHoliday.name}>
      {publicHoliday.name}
    </GenericInfoText>
    {/* <Lock css={GenericInfoIconStyles} /> */}
  </GenericInfoContainer>
);

export const PauseFullCalendarDayOuter = ({
  isActive = false,
  date: DATE,
  lockedFor = [],
  birthdays = [],
  publicHoliday,
  isNonWorkingDay,
  children,
  isLarge,
  onClick = () => null,
}: {
    isActive?: boolean,
    date: moment.Moment,
    lockedFor?: Array<{
      endDate: string
      fullName: string
      reason: string | null
      startDate: string
    }>,
    birthdays?: Array<{
      date: string
      fullName: string
    }>,
    publicHoliday: {
      calendarID: string
      date: string
      holidayApiID: string
      id: string
      name: string
      orgID: string
    } | null,
    isNonWorkingDay?: boolean | {
      calendarID: string,
      date: string,
      holidayApiID: string,
      id: string,
      name: string,
      orgID: string,
    },
    children: React.ReactNode,
    isLarge?: boolean,
    onClick?: (any?) => any

}) => {
  const activeColor = 'pause.secondary.main';
  const hasLockedFor = get(lockedFor, '0');
  const hasBirthdays = get(birthdays, '0');
  const showExtraInfo = hasLockedFor || hasBirthdays || publicHoliday;

  const date = moment(DATE).date();
  const day = moment(DATE).format('ddd');

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      css={(theme) => ({
        height: isLarge ? 'auto' : rem(FIXED_DAY_HEIGHT),
        padding: theme.spacing(isLarge ? CALENDAR_LARGE_DAY_PADDING : CALENDAR_DAY_PADDING),
        backgroundColor: isNonWorkingDay ? theme.get(NON_WORKING_DAY_BACKGROUND) : theme.get('palette.pause.background.light'),
        ...(isActive ? {
          boxShadow: `0 -${theme.spacing(CALENDAR_BORDER_WIDTH)}px 0 0 ${theme.get(`palette.${activeColor}`)}`,
        } : {}),
        // '&: hover': {
        //   backgroundColor: theme.get('palette.pause.background.medium'),
        // },
      })}
      onClick={onClick}
    >
      <div
        css={(theme) => ({
          display: 'grid',
          gridGap: theme.spacing(2),
          gridTemplateColumns: 'auto auto',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        })}
      >
        <div>
          {isLarge && (
          <PauseTypography
            variant="caption"
            color={isActive ? activeColor : 'pause.primary.light'}
          >
            {day}
          </PauseTypography>
          )}
          <PauseTypography
            variant={isLarge ? 'title' : 'button'}
            color={isActive ? activeColor : 'pause.primary.medium'}
          >
            {date}
          </PauseTypography>
        </div>
        {(showExtraInfo || true) && (
        <>
          {/* eslint-disable-next-line no-nested-ternary */}
          <ShowExtraInfo
            // lockedFor={[{ fullName: 'Team1', reason: null }, { fullName: 'team2', reason: 'just like that kya kar loge?' }]}
            // birthdays={[{ fullName: 'Varun Barad' }]}
            // publicHolidays={[{ name: 'Some public holiday' }, { name: 'One more ph' }]}
            birthdays={birthdays}
            lockedFor={lockedFor}
            publicHolidays={publicHoliday ? [publicHoliday] : []}
          />
        </>
        )}
      </div>

      {children}
    </div>

  );
};
