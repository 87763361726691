import React, {
  useState, useMemo, useCallback,
} from 'react';
import moment from 'moment';
import { PauseFullCalendar } from '../components/PauseCalendar/PauseFullCalendar';
import { DateNavigator } from '../components/PauseCalendar/DateNavigator';
import { PauseCalendarSwitcher } from './PauseCalendarSwitcher';
import { MONTH_FORMAT } from '../components/PauseCalendar/PauseFullCalendar/constants';

const GRID_GAP_BETWEEN_HEADER_AND_CALENDAR = 4;

export const PauseCalendarRegular = ({
  viewType = 'full',
  onChangeView,
  leaves,
  applyLink,
  currentMonth,
  onMonthChange,
  publicHolidays,
  birthdays,
  lockedDates = [],
  hideSwitcher,
  currentCycleStartDate = moment(),
  nonWorkingDays = [1, 7],
  currentCycleEndDate = moment().add(1, 'year').subtract(1, 'day'),
}) => {
  const [silentUpdate, setSilentUpdate] = useState(true);
  const earliestMonthToShow = currentCycleStartDate
    .clone()
    .subtract(1, 'year')
    .add(1, 'day')
    .format(MONTH_FORMAT);

  const latestMonthToShow = currentCycleEndDate
    .clone()
    .add(1, 'year')
    .subtract(1, 'day')
    .format(MONTH_FORMAT);

  const onScroll = useCallback(
    (newDate) => {
      setSilentUpdate(true);
      onMonthChange(moment(newDate, MONTH_FORMAT).format(MONTH_FORMAT));
    }, [onMonthChange],
  );

  return (
    <div css={(theme) => ({
      height: '100%',
      width: '100%',
      display: 'grid',
      gridGap: theme.spacing(GRID_GAP_BETWEEN_HEADER_AND_CALENDAR),
      alignItems: 'start',
      gridTemplateRows: 'auto 1fr',
    })}
    >
      <div css={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      })}
      >
        <DateNavigator
          startDate={moment(currentMonth, MONTH_FORMAT)}
          onPrev={() => {
            setSilentUpdate(false);
            onMonthChange(
              moment(currentMonth, MONTH_FORMAT)
                .subtract(1, 'month').format(MONTH_FORMAT),
            );
          }}
          prevDisabled={moment(currentMonth).format(MONTH_FORMAT) === earliestMonthToShow}
          onNext={() => {
            setSilentUpdate(false);
            onMonthChange(
              moment(currentMonth, MONTH_FORMAT)
                .add(1, 'month').format(MONTH_FORMAT),
            );
          }}
          nextDisabled={moment(currentMonth).format(MONTH_FORMAT) === latestMonthToShow}
        />
        {!hideSwitcher && (
        <PauseCalendarSwitcher
          applyLink={applyLink}
          viewType={viewType}
          onChangeView={onChangeView}
        />
        )}
      </div>

      <div css={(theme) => ({
        position: 'relative',
        height: `calc(100% - ${theme.spacing(GRID_GAP_BETWEEN_HEADER_AND_CALENDAR)}px)`,
      })}
      >
        <div css={() => ({
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        })}
        >
          <PauseFullCalendar
            currentMonth={currentMonth}
            onScroll={onScroll}
            birthdays={birthdays}
            earliestMonthToShow={earliestMonthToShow}
            latestMonthToShow={latestMonthToShow}
            leaves={leaves || []}
            publicHolidays={publicHolidays}
            nonWorkingDays={nonWorkingDays}
            silentUpdate={silentUpdate}
            lockedDates={lockedDates}
          />
        </div>
      </div>
    </div>
  );
};
