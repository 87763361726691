import { useEffect, useMemo, useState } from 'react';
import { get } from 'lodash';
import { usePeople } from '../api/people';
import { useMe } from '../api/me';

const hasOverlaps = (arr1: string[]) => (arr2: string[]) => {
  for (let i = 0; i < arr1.length; i += 1) {
    const t1 = arr1[i];
    if (arr2.includes(t1)) return true;
  }
};

export const usePreferences = () => {
  const { me, loading: meLoading } = useMe();
  const { people, loading: loadingPeople } = usePeople();
  const [selectedPeopleIDs, setSelectedPeopleIDs] = useState<string[]>([]);
  useEffect(() => {
    if (meLoading || loadingPeople) return;
    const selectedPeople: string[] = me?.preferencesObject?.effectivePersonIDs || [];
    const defaultSelectedPeople = (people || []).filter((p) => hasOverlaps(me?.teamIDs || [])(p.teamIDs));
    const defaultSelectedPeopleIds = defaultSelectedPeople.map((p) => p.id);
    setSelectedPeopleIDs(selectedPeople.length ? selectedPeople : defaultSelectedPeopleIds);
  }, [meLoading, loadingPeople, me, people]);

  return useMemo(
    () => ({
      selectedPeopleIDs,
    }),
    [selectedPeopleIDs],
  );
};
