// TODO - find a good place for this
import { useCallback, useEffect, useMemo, useState } from 'react';
import { get } from 'lodash';
import moment, { MomentInput } from 'moment';
import { attachTeamToPeople, removeAllowanceFromPeople, stripPeople } from '../../../utils/data';
import {
  LEAVE_APPLICATION_JOURNEY_KEY,
  LEAVE_APPLICATION_JOURNEY_NEW_APPLICATION_KEY,
  useEnhancedRouter,
} from '../../../router';
import { useWindowSize } from '../../hooks/dom';
import { usePreferences } from '../../hooks/preferences';
import { useTeams } from '../../api/teams';
import { useAllLeaves, useLeaves } from '../../api/leaves';
import { usePeople as _usePeople } from '../../api/people';
import { useOrg } from '../../api/org';
import { getCycleEndpoints } from '../../../utils/datetime';
import { mapObject } from '../../../utils/mapObject';
import { AppStateEnum, useAppState } from '../../api/app-state';

export const usePeople = () => {
  const { org, loading: loadingOrg } = useOrg();

  const { teams, loading: loadingTeams } = useTeams();
  const { people, isComplete: allPeopleLoaded } = _usePeople();

  const memoizedPeople = useMemo(() => people && teams && stripPeople(attachTeamToPeople({ people, teams })), [
    people,
    teams,
  ]);

  return useMemo(
    () => ({
      people:
        org && get(org, 'disableDeductibleLeaveTypes', false)
          ? removeAllowanceFromPeople(memoizedPeople)
          : memoizedPeople,
      done: allPeopleLoaded && !loadingTeams && !loadingOrg,
    }),
    [org, allPeopleLoaded, loadingTeams, memoizedPeople, loadingOrg],
  );
};

export const useSelectedPeople = () => {
  const { people, done } = usePeople();

  const { selectedPeopleIDs } = usePreferences();

  const selectedPeople = useMemo(() => {
    if (done) {
      const filteredPeople = (people || []).filter((p: any) => (selectedPeopleIDs as any).includes(p.id));
      return filteredPeople.map((fp) => ({
        ...fp,
        allowance: get(fp, 'allowance', []).reduce((acc, curr) => acc + (curr.leaves || 0), 0),
      }));
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [done, people, selectedPeopleIDs]);

  return useMemo(
    () => ({
      selectedPeople,
      done,
    }),
    [selectedPeople, done],
  );
};

export const useLeavesForSelectedPeople = ({ currentStartDate }) => {
  const router = useEnhancedRouter();
  const { selectedPeople, done: donePeopleLoading } = useSelectedPeople();
  const { org } = useOrg();
  const { startDate, endDate } = useMemo(
    () =>
      getCycleEndpoints({
        cycleStartMonth: get(org, 'cycleStartMonth'),
        today: moment(currentStartDate),
      }),
    [org, currentStartDate],
  );
  const { appState } = useAppState();
  const { loading: leavesLoading, isComplete } = useLeaves({
    refetchOnWindowFocus: 'always',
    enabled: [AppStateEnum.ready, AppStateEnum.isFirstLogin].includes(appState as AppStateEnum),
    params: mapObject((x: MomentInput) => moment(x).format('YYYY-MM-DD'))({ startDate, endDate }),
  });
  const { leaves } = useAllLeaves();

  const formattedLeaves = useMemo(
    () =>
      leaves.length && donePeopleLoading && !leavesLoading
        ? ((leaves || []) as any)
            .filter((leave) => selectedPeople.find((s: any) => s.id === leave.applicantID))
            .map((leave) => ({
              ...leave,
              href: router.getStopHref(LEAVE_APPLICATION_JOURNEY_KEY, LEAVE_APPLICATION_JOURNEY_NEW_APPLICATION_KEY, {
                id: leave.id,
              }),
              applicant: {
                ...leave.applicant,
                ...(selectedPeople && selectedPeople.find((person) => person.id === leave.applicant.id)),
              },
            }))
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [leaves, isComplete, selectedPeople, donePeopleLoading, leavesLoading],
  );

  return useMemo(
    () => ({
      leaves: formattedLeaves,
      loading: !donePeopleLoading || leavesLoading,
      done: donePeopleLoading && !leavesLoading,
    }),
    [formattedLeaves, donePeopleLoading, leavesLoading],
  );
};

export const useResponsiveCalendarProps = () => {
  const [listCalendarView, setListCalendarView] = useState('month');
  const [listCalendarViewCount, setListCalendarViewCount] = useState(1);
  const [enforceListCalendarOnly, setEnforceListCalendarOnly] = useState(false);
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const { width } = useWindowSize();

  // eslint-disable-next-line no-shadow
  const fn = useCallback((width) => {
    if (width <= 1280 && width > 1024) {
      setListCalendarView('week');
      setListCalendarViewCount(2);
      setEnforceListCalendarOnly(false);
      setIsSidebarHidden(false);
    } else if (width <= 1024 && width > 800) {
      setListCalendarView('week');
      setListCalendarViewCount(2);
      setIsSidebarHidden(false);
      setEnforceListCalendarOnly(true);
    } else if (width <= 800 && width > 500) {
      setListCalendarView('week');
      setListCalendarViewCount(1);
      setIsSidebarHidden(true);
      setEnforceListCalendarOnly(true);
    } else if (width <= 500) {
      setListCalendarView('week');
      setListCalendarViewCount(1);
      setIsSidebarHidden(true);
      setEnforceListCalendarOnly(true);
    } else {
      setListCalendarView('month');
      setListCalendarViewCount(1);
      setEnforceListCalendarOnly(false);
      setIsSidebarHidden(false);
    }
  }, []);

  useEffect(() => {
    fn(width);
  }, [fn, width]);

  const memoizedValues = useMemo(
    () => ({
      listCalendarView,
      listCalendarViewCount,
      enforceListCalendarOnly,
      isSidebarHidden,
    }),
    [listCalendarView, listCalendarViewCount, enforceListCalendarOnly, isSidebarHidden],
  );

  return memoizedValues;
};
