import React from 'react';
import moment from 'moment';
import Head from 'next/head';
import { get } from 'lodash';
import { getCalendarMainGridStyles, PauseListCalendar } from '../../components/PauseCalendar/PauseListCalendar';
import { PauseFullCalendarSidebar } from '../../composites/PauseFullCalendarSidebar';
import { PauseCalendarRegular } from '../../composites/PauseCalendarRegular';
import { CalendarsStoreWrapper } from './store';
import { DAY_FORMAT } from '../../components/PauseCalendar/PauseFullCalendar/constants';
import { PauseLoader } from '../../components/PauseLoader';
import { Layout } from '../_common/Layout';

export const PauseHomeCalendars = () => (
  <CalendarsStoreWrapper>
    {({
      leaves, selectedPeople,
      loading, applyLink,
      viewType, onChangeViewType,
      currentStartDate, onChangeCurrentStartDate,
      publicHolidays,
      birthdays,
      currentCycle,
      nonWorkingDays,
      lockedDates,
      // responsiveProps: {
      //   listCalendarView, listCalendarViewCount, enforceListCalendarOnly,
      //   isSidebarHidden,
      // },
    }) => (
      <Layout {...{
        showAuthToken: false,
        fullPage: true,
      }}
      >
        {loading ? <PauseLoader /> : (
          <>

            <div css={() => ({
              height: '100%',
            })}
            >
              <Head>
                <title>
                  Home | Pause
                </title>
              </Head>
              {viewType === 'full' && (

              <div css={(theme) => ({
                ...getCalendarMainGridStyles(theme),
                height: '100%',
              })}
              >
                <PauseFullCalendarSidebar
                  selectedPeople={selectedPeople}
                />
                <div css={() => ({
                  position: 'relative',
                  height: '100%',
                })}
                >
                  <div css={() => ({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                  })}
                  >
                    <PauseCalendarRegular
                      applyLink={applyLink}
                      currentMonth={currentStartDate}
                      onMonthChange={onChangeCurrentStartDate}
                      leaves={leaves}
                      birthdays={birthdays}
                      publicHolidays={publicHolidays}
                      viewType={viewType}
                      selectedPeople={selectedPeople}
                      onChangeView={onChangeViewType}
                      nonWorkingDays={nonWorkingDays}
                      currentCycleStartDate={get(currentCycle, 'startDate')}
                      currentCycleEndDate={get(currentCycle, 'endDate')}
                      lockedDates={lockedDates}
                    />
                  </div>
                </div>
              </div>
              )}
              {(viewType === 'list') && (

                <PauseListCalendar
                  leaveComponentType="list"
                  applyLink={applyLink}
                  onCalendarViewChange={onChangeViewType}
                  startDate={moment(currentStartDate, DAY_FORMAT)}
                  leaves={leaves}
                  publicHolidays={publicHolidays}
                  onChangeStartDate={onChangeCurrentStartDate}
                  nonWorkingDays={nonWorkingDays}
                  selectedPeople={selectedPeople}
                  currentCycleStartDate={get(currentCycle, 'startDate')}
                  currentCycleEndDate={get(currentCycle, 'endDate')}
                />
              )}
            </div>
          </>
        )}
      </Layout>
    )}

  </CalendarsStoreWrapper>

);
