import React from 'react';
import { PauseTypography } from '../PauseTypography';

export const PauseChip = ({ children = <></>, color = 'primary' }: {
  children?: React.ReactNode,
  color?: string
}) => (
  <div css={(theme) => ({
    minWidth: theme.spacing(5),
    backgroundColor: theme.get(`palette.pause.${color}.light`),
    color: theme.get(`palette.pause.${color}.dark`),
    borderRadius: theme.spacing(1),
    borderWidth: theme.spacing(0.1875),
    borderColor: theme.get(`palette.pause.${color}.dark`),
    borderStyle: 'solid',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    height: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  })}
  >
    <PauseTypography variant="number">
      {children}
    </PauseTypography>
  </div>
);
