import React from 'react';
import Link from 'next/link';
import { PausePerson } from '../PausePerson';
import { PauseChip } from '../PauseChip';
import { ExpressiveColors } from '../PauseTheme/colors';
import { PauseSimpleTooltip } from '../PauseSimpleTooltip';
import { pluralize } from '../../../utils/strings';

const BasePersonWithAllowance = ({
  fullName,
  image,
  teamName,
  allowance,
  color,
  hideAllowance,
}: {
  fullName: string;
  image?: string;
  teamName: string;
  allowance: number;
  color: ExpressiveColors;
  hideAllowance?: boolean;
}) => (
  <div
    css={(theme) => ({
      display: 'grid',
      gridGap: theme.spacing(2),
      justifyContent: 'space-between',
      alignItems: 'center',
      gridTemplateColumns: '1fr auto',
    })}
  >
    <PausePerson color={color} teamName={teamName} image={image} fullName={fullName} />
    {/* eslint no-nested-ternary: off */}
    {!hideAllowance ? (
      allowance === 0 || allowance ? (
        <PauseSimpleTooltip
          aria-label={`${allowance} ${pluralize('leave')('leaves')(allowance)} applied.`}
          title={`${allowance} ${pluralize('leave')('leaves')(allowance)} applied. Click to view details.`}
        >
          <div>
            <PauseChip color={color}>{allowance}</PauseChip>
          </div>
        </PauseSimpleTooltip>
      ) : (
        <></>
      )
    ) : (
      <div />
    )}
  </div>
);

export const PausePersonWithAllowance = ({
  id,
  fullName,
  image,
  teamName,
  allowance,
  color,
  linkToProfile = true,
  hideAllowance,
}: {
  id: string;
  fullName: string;
  image?: string;
  teamName: string;
  allowance: number;
  color: ExpressiveColors;
  linkToProfile?: boolean;
  hideAllowance?: boolean;
}) => {
  if (linkToProfile) {
    return (
      <Link href={`/profile/${id}`}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a>
          <BasePersonWithAllowance
            fullName={fullName}
            image={image}
            teamName={teamName}
            allowance={allowance}
            color={color}
            hideAllowance={hideAllowance}
          />
        </a>
      </Link>
    );
  }
  return (
    <BasePersonWithAllowance
      fullName={fullName}
      image={image}
      teamName={teamName}
      allowance={allowance}
      color={color}
    />
  );
};
