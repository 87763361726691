import {
  useCallback, useState, useMemo, useEffect,
} from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { useQueryClient } from 'react-query';
import {
  useLeavesForSelectedPeople,
  usePeople,
  useSelectedPeople,
} from './utils';
import {
  LEAVE_APPLICATION_JOURNEY_KEY,
  LEAVE_APPLICATION_JOURNEY_NEW_APPLICATION_KEY,
  useEnhancedRouter,
} from '../../../router';
import { getCycleEndpoints } from '../../../utils/datetime';
import { useTeams } from '../../api/teams';
import { useMe } from '../../api/me';
import { useOrg } from '../../api/org';
import { useAllHands } from '../../api/allhands';
import fromEntries from '../../../utils/fromEntries';

const allDays = moment.weekdays();
const weekdayNumberMap = fromEntries(moment.weekdays().map((weekday, idx) => [
  weekday,
  idx + 1,
]));

// TODO - explore iterator on useQuery
export const useCalendarsStore = () => {
  const { getStopHref } = useEnhancedRouter();
  const queryClient = useQueryClient();
  const publicHolidaysQuery = queryClient.getQueryData<any>('publicHolidaysForCalendar');
  const publicHolidays = publicHolidaysQuery?.data || [];
  const { teams } = useTeams();
  const { allHands } = useAllHands();
  const lockedDates = (allHands || []).map((ld) => ({
    startDate: ld.startDate,
    fullName: ld?.team?.name || (teams || []).find((t) => t.id === ld?.team?.id)?.name,
    endDate: ld.endDate,
    reason: ld.reason,
  }));
  const { people: allPeople, done: doneLoadingPeople } = usePeople();
  const { selectedPeople, done: doneLoadingSelectedPeople } = useSelectedPeople();
  const [currentStartDate, setCurrentStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const { leaves } = useLeavesForSelectedPeople({ currentStartDate });
  const { org } = useOrg();
  // const { me } = useMe();
  const [viewType, setViewType] = useState('full'); // full | list
  // const responsiveProps = useResponsiveCalendarProps();
  const birthdays = useMemo(() => (allPeople || [])
    .filter((p) => Boolean(p.birthDate))
    .map(({ birthDate, fullName }) => ({
      fullName,
      date: birthDate,
    })), [allPeople]);
  const applyLink = useMemo(() => getStopHref(
    LEAVE_APPLICATION_JOURNEY_KEY, LEAVE_APPLICATION_JOURNEY_NEW_APPLICATION_KEY,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  const onChangeViewType = useCallback((view) => {
    setViewType(
      ['full', 'list'].includes(view) ? view : 'full',
    );
  }, []);

  const onChangeCurrentStartDate = useCallback((newDate) => {
    setCurrentStartDate(moment(newDate).format('YYYY-MM-DD'));
  }, []);

  const currentCycle = useMemo(() => getCycleEndpoints(
    { cycleStartMonth: get(org, 'cycleStartMonth', undefined) },
  ), [org]);

  const { me } = useMe();
  const nonWorkingDays = useMemo(() => {
    const workingDays = me?.workingDays || [];
    const nwd = allDays.filter((day) => !workingDays.includes(day.toUpperCase()));
    return nwd.map((d) => weekdayNumberMap[d]);
  }, [me]);

  return {
    selectedPeople,
    leaves,
    applyLink,
    viewType,
    birthdays,
    publicHolidays,
    onChangeViewType,
    currentStartDate,
    // responsiveProps,
    onChangeCurrentStartDate,
    currentCycle,
    nonWorkingDays,
    loading: !(doneLoadingPeople
      && doneLoadingSelectedPeople),
    lockedDates,
    // loading: !(allPeople.length > 0),
  };
};

export const CalendarsStoreWrapper = ({ children }) => {
  const storeEntities = useCalendarsStore();
  return children(storeEntities);
};

export const useSidebarMultiSelect = () => {
  const { updatePreferences } = useMe();
  const { teams, loading: loadingTeams } = useTeams();
  const { selectedPeople, done: doneLoadingSelectedPeople } = useSelectedPeople();
  const { people: allPeople, done: doneLoadingPeople } = usePeople();

  const [favorites, setFavorites] = useState<any[]>([]);

  useEffect(() => {
    if (doneLoadingSelectedPeople && selectedPeople.length) {
      setFavorites(selectedPeople);
    }
  }, [doneLoadingSelectedPeople, selectedPeople]);

  const onChangeSelectedPeople = useCallback(() => updatePreferences(favorites.map((p) => p.id)), [favorites]);

  const onUpdateFavorites = useCallback((selectedPeopleObjects) => {
    const filteredPeople = (allPeople || []).filter((p: any) => (selectedPeopleObjects.map((s) => s.id) as string[])
      .includes(p.id));
    const res = (filteredPeople.map((fp) => ({
      ...fp,
      allowance: get(fp, 'allowance', []).reduce((acc, curr) => acc + (curr.allowanceRemaining || 0), 0),
    }))
    );
    setFavorites(res);
  }, [allPeople]);

  return {
    onChangeSelectedPeople,
    selectedPeople: favorites,
    teams,
    people: allPeople,
    done: doneLoadingSelectedPeople && !loadingTeams && doneLoadingPeople,
    onUpdateFavorites,
  };
};

export const SidebarMultiSelectWrapper = ({ children }) => {
  const d = useCalendarsStore();
  return children(d);
};
