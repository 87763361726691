import fromEntries from './fromEntries';

type MapObject = <A, B>(fn: (a: A) => B) =>
        <C extends {[key: string]: A }>(obj: C) => { [key in keyof C]: B}
// @ts-ignore
export const mapObject: MapObject = (fn) => (obj) => {
  if (!obj) return {};
  const kvPairs = Object.entries(obj);
  const mapped = kvPairs.map((kvPair): [string, any] => [kvPair[0], fn(kvPair[1])]);
  return fromEntries(mapped);
};
