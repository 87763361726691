import React from 'react';
import { PauseHomeCalendars } from '../src/integrated-components/calendar/index';

export default function Page() {
  // IMPORTANT NOTE - only for this page, layout is configured internally because
  // full calendar and list calendar need different kinds of layout
  return (
    <>
      <PauseHomeCalendars />
    </>

  );
}
