import React from 'react';
import moment from 'moment';
import { range, get } from 'lodash';
import { DAY_FORMAT, MONTH_FORMAT } from '../PauseFullCalendar/constants';
import { PauseTypography } from '../../PauseTypography';

import {
  LIST_CALENDAR_BORDER_COLOR,
  LIST_CALENDAR_DAY_GAP,
  LIST_CALENDAR_DEFAULT_BG_COLOR,
  LIST_CALENDAR_MIN_DAY_WIDTH,
  LIST_CALENDAR_NON_WORKING_DAY_COLOR,
} from '../PauseListCalendar/constants';
import { DateNavigator } from '../DateNavigator';
import { PauseCalendarSwitcher } from '../../../composites/PauseCalendarSwitcher';
import { PublicHolidays } from '../PauseFullCalendar';

const PauseListCalendarHeaderDay = ({
  noOfPeopleToShow,
  date: DATE,
  isNonWorkingDay: IS_NON_WORKING_DAY,
  publicHoliday,
}: {
    noOfPeopleToShow: number,
    date: moment.Moment,
    isNonWorkingDay: boolean,
    publicHoliday: PublicHolidays
}) => {
  const isNonWorkingDay = IS_NON_WORKING_DAY || publicHoliday;
  const weekday = moment.weekdays((moment(DATE).weekday())).slice(0, 3);
  const date = moment(DATE).date();
  const isActive = moment(DATE).startOf('day').isSame(moment().startOf('day'));

  return (
    <div
      css={(theme) => ({
        display: 'grid',
        minWidth: theme.spacing(LIST_CALENDAR_MIN_DAY_WIDTH),
        justifyItems: 'center',
        gridGap: theme.spacing(1),
        paddingTop: theme.spacing(0.5),
        background: isNonWorkingDay
          ? theme.get(LIST_CALENDAR_NON_WORKING_DAY_COLOR)
          : theme.get(LIST_CALENDAR_DEFAULT_BG_COLOR),
        position: 'relative',
        '&:not(:first-child)': {
          borderLeft: `${theme.spacing(LIST_CALENDAR_DAY_GAP)}px solid ${theme.get(LIST_CALENDAR_BORDER_COLOR)}`,
          '.pause-list-calendar-header-column': {
            marginLeft: -theme.spacing(LIST_CALENDAR_DAY_GAP),
            borderLeft: `${theme.spacing(LIST_CALENDAR_DAY_GAP)}px solid ${theme.get(LIST_CALENDAR_BORDER_COLOR)}`,
          },
        },
      })}
    >

      <PauseTypography variant="captionSmall" color="pause.primary.light">{weekday}</PauseTypography>
      <div css={(theme) => ({
        background: isNonWorkingDay
          ? theme.get(LIST_CALENDAR_NON_WORKING_DAY_COLOR)
          : theme.get(LIST_CALENDAR_DEFAULT_BG_COLOR),
        ...(isActive && {
          boxShadow: `0 -${theme.spacing(0.25)}px 0 0 ${theme.get('palette.pause.secondary.main')}`,
        }),
      })}
      >
        <PauseTypography
          variant="button"
          color={isActive ? 'pause.secondary.main' : 'pause.primary.medium'}
        >
          {date}
        </PauseTypography>
      </div>

      <div
        className="pause-list-calendar-header-column"
        css={(theme) => ({
          position: 'absolute',
          top: '100%',
          left: 0,
          right: 0,
          height: `max(calc(${noOfPeopleToShow} * ${theme.spacing(8.45)}px), 700px)`,
          background: isNonWorkingDay
            ? theme.get(LIST_CALENDAR_NON_WORKING_DAY_COLOR)
            : theme.get(LIST_CALENDAR_DEFAULT_BG_COLOR),
        })}
      >
        {publicHoliday ? (

          <PauseTypography
            variant="subtitle"
            color="pause.primary.medium"
            css={(theme) => ({
              display: 'grid',
              justifyContent: 'center',
              marginTop: theme.spacing(4),
              textAlign: 'center',
              fontSize: theme.spacing(2.5),
              lineHeight: 1.2,
            })}
          >
            {[...(get(publicHoliday, 'name', ''))]
              .map((char) => <span key={char} css={() => ({ minHeight: '1rem' })}>{char}</span>)}
          </PauseTypography>
        ) : null}
      </div>
    </div>

  );
};

type Props = {
  noOfPeopleToShow: number,
  startDate: moment.Moment,
  endDate: moment.Moment,
  viewCount: number,
  view: moment.DurationInputArg2,
  setStartDate: (any?) => any,
  nonWorkingDays: Array<any>,
  applyLink: {
    pathname: string,
    query: {
      journey: string,
      stop: string
    }
  },
  onCalendarViewChange: (any?) => any,
  hideSwitcher?: boolean,
  publicHolidays: Array<any>,
  currentCycleStartDate: moment.Moment,
  currentCycleEndDate: moment.Moment

}

export const PauseListCalendarHeader = ({
  noOfPeopleToShow,
  startDate,
  endDate,
  viewCount,
  view,
  setStartDate,
  nonWorkingDays = [],
  applyLink,
  onCalendarViewChange,
  hideSwitcher,
  publicHolidays = [],
  currentCycleStartDate = moment(),
  currentCycleEndDate = moment().add(1, 'year').subtract(1, 'day'),
}: Props) => {
  const noOfDays = moment(endDate, DAY_FORMAT)
    .diff(moment(startDate, DAY_FORMAT), 'days') + 1;

  const days = range(noOfDays)
    .map((number) => moment(startDate)
      .clone().add(number, 'days'));
  const earliestMonthToShow = moment(currentCycleStartDate)
    .clone()
    .subtract(1, 'year')
    .add(1, 'day')
    .format(MONTH_FORMAT);

  const latestMonthToShow = moment(currentCycleEndDate)
    .clone()
    .add(1, 'year')
    .subtract(1, 'day')
    .format(MONTH_FORMAT);

  return (
    <div css={() => ({
      position: 'relative',
    })}
    >
      <div css={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(4),
      })}
      >
        <DateNavigator
          startDate={startDate}
          endDate={endDate}
          onPrev={() => setStartDate(
            moment(startDate)
              .subtract(viewCount, view),
          )}
          onNext={() => {
            setStartDate(
              moment(startDate)
                .add(viewCount, view),
            );
          }}
          prevDisabled={moment(startDate).format(MONTH_FORMAT) === earliestMonthToShow}
          nextDisabled={moment(startDate).format(MONTH_FORMAT) === latestMonthToShow}
        />
        {!hideSwitcher && (
          <PauseCalendarSwitcher
            // @ts-ignore
            applyLink={applyLink}
            viewType="list"
            onChangeView={onCalendarViewChange}
          />
        )}
      </div>

      {/* <div css={(theme) => ({ */}
      {/*  height: theme.spacing(4), */}
      {/* })} */}
      {/* /> */}
      <div css={() => ({
        display: 'grid',
        gridTemplateColumns: `repeat(${endDate.diff(startDate, 'days') + 1}, 1fr)`,
        // gridGap: theme.spacing(LIST_CALENDAR_DAY_GAP),
      })}
      >
        {days.map((day, index) => (
          <PauseListCalendarHeaderDay
            // eslint-disable-next-line react/no-array-index-key
            key={`${day}-${index}`}
            publicHoliday={publicHolidays.find((ph) => moment(ph.date).isSame(day))}
            noOfPeopleToShow={noOfPeopleToShow}
            isNonWorkingDay={nonWorkingDays.includes(day.weekday() + 1)}
            date={day}
          />
        ))}
      </div>
    </div>
  );
};
