/* eslint no-underscore-dangle: 0 */ // --> OFF
import * as _moment
  from 'moment';
import { Moment } from 'moment';
import { extendMoment } from 'moment-range';
import {
  chain, groupBy, range,
} from 'lodash';
import {
  DAY_FORMAT, LEAVE_MARKERS, MONTH_FORMAT, MAX_LEAVES_TO_SHOW,
} from './constants';
import { Leaves } from '.';

const moment = extendMoment(_moment);

const { START_TYPES, END_TYPES } = LEAVE_MARKERS;

export const getLengthOfALeave = ({
  startDate,
  endDate,
  startType,
  endType,
}: {
    startDate: Moment,
    endDate: Moment,
    startType: string,
    endType: string
}) => {
  let difference = moment(endDate, DAY_FORMAT)
    .diff(moment(startDate, DAY_FORMAT), 'days') + 1;
  if (startType === START_TYPES[1]) {
    difference -= 0.5;
  }
  if (endType === END_TYPES[0]) {
    difference -= 0.5;
  }
  return difference;
};

export type LeaveType = Leaves & {
  _endDate: Moment,
  _endType: string,
  _extendedFromPrevWeek: boolean,
  _extendsToNextWeek: boolean,
  _isHalf: boolean,
  _isOneFullDay: boolean,
  _spansMultipleDays: boolean,
  _startDate: Moment,
  _startType: string,
  _startsSecondHalf: boolean,
  _thisWeek: string,
  _totalDays: number
}

const doesGivenLeaveOverlapWithAnyOfTheLeaves = (
  { leave, leavesToCheck }: {
    leave: LeaveType,
    leavesToCheck: Array<LeaveType>
  },
) => leavesToCheck.reduce((acc, thisLeave) => {
  const leaveRange = moment.range(
    leave._startDate.startOf('day'), leave._endDate.endOf('day'),
  );
  const thisLeaveRange = moment.range(
    thisLeave._startDate.startOf('day'), thisLeave._endDate.endOf('day'),
  );
  if (leaveRange.overlaps(thisLeaveRange)
            || leaveRange.contains(thisLeaveRange)
            || thisLeaveRange.contains(leaveRange)) {
    return true;
  }
  return acc;
}, false);

export const assignPositionsToLeaves = (obj) => chain(obj)
  .mapValues()
  .map((leaves: Array<LeaveType>) => chain(leaves)
    .sortBy((l1) => -l1._totalDays)
    .sortBy((l1) => l1._startDate.unix())
    .reduce((acc: Array<Array<LeaveType>>, leave: LeaveType) => {
      let overlapDetected = false;

      const modifiedAcc = acc.map((leaveGroup) => {
        if (!overlapDetected) {
          const doesLeaveOverlap = doesGivenLeaveOverlapWithAnyOfTheLeaves(
            { leave, leavesToCheck: leaveGroup },
          );

          if (!doesLeaveOverlap) {
            overlapDetected = true;
            return [...leaveGroup, leave];
          }
        }
        return leaveGroup;
      });

      if (!overlapDetected) {
        return [...acc, [leave]];
      }
      return modifiedAcc;
    }, [])

    .map((leaveGroup, index) => leaveGroup.map((leave) => ({
      ...leave,
      _position: index + 1,
    })))
    .value())
  .flattenDeep()
  .groupBy((l) => l._thisWeek)
  .value();

export const splitLeavesIntoDays = (obj) => chain(obj)
  .mapValues()
  .map((leaves) => chain(leaves)
    .map((leave) => {
      let absoluteTotalDays = Math.ceil(leave._totalDays);
      // Since we rely on the total number of days to split the leave into
      // those many days, we need to add this special case
      if (leave.startType === START_TYPES[1] && leave.endType === END_TYPES[0]) {
        absoluteTotalDays += 1;
      }
      return range(absoluteTotalDays).map((number) => {
        const thisDay = moment(leave._startDate).clone().add(number, 'days');
        return ({
          ...leave,
          // currently its only here that we ensure that totalDays
          // of a leave is set to overall total if its the firstLeave,
          // TODO - have a different variable to store the overall total days
          _totalDays: getLengthOfALeave({
            startDate: thisDay,
            endDate: number === 0 ? leave._endDate : thisDay,
            startType: number === 0 ? leave._startType : START_TYPES[0],
            endType: number === 0 || number === absoluteTotalDays - 1
              ? leave._endType : END_TYPES[1],
          }),
          _isMain: number === 0,
          _startDate: thisDay.clone(),
          _endDate: number === 0 ? leave._endDate : thisDay.clone(),
          _startType: number === 0 ? leave._startType : START_TYPES[0],
          _endType: number === absoluteTotalDays - 1 ? leave.endType : END_TYPES[1],
        });
      })
        .map((l) => ({
          ...l,
          _isHalf: l._totalDays === 0.5,
          _startsSecondHalf: l._startType === START_TYPES[1],
        }));
    })

    .value())
  .flattenDeep()
  .groupBy((l) => l._thisWeek)
  .mapValues()
  .reduce((acc, leavesForTheWeek, weekKey) => {
    acc[weekKey] = groupBy(
      leavesForTheWeek,
      (l) => l._startDate.format(DAY_FORMAT),
    );
    return acc;
  }, {})
  .value();

export const sortStartTypes = (leave) => leave.startType;

const getLeaveMetaWithinAGivenWeek = ({ leave, week }: {
  leave: Leaves,
  week: {
    startDate: Moment,
    endDate: Moment
  }
}) => {
  const startDate = moment.max([
    moment(leave.startDate, DAY_FORMAT), moment(week?.startDate, DAY_FORMAT),
  ]);
  const endDate = moment.min([
    moment(leave.endDate, DAY_FORMAT), moment(week?.endDate, DAY_FORMAT),
  ]);

  const computedStartType = startDate.isSame(
    moment(leave.startDate, DAY_FORMAT),
  ) ? leave.startType : START_TYPES[0];

  const computedEndType = endDate.isSame(
    moment(leave.endDate, DAY_FORMAT),
  ) ? leave.endType : END_TYPES[1];

  return {
    _totalDays: getLengthOfALeave({
      startDate,
      endDate,
      endType: computedEndType,
      startType: computedStartType,
    }),
    _startDate: startDate,
    _endDate: endDate,
    _startType: computedStartType,
    _endType: computedEndType,
  };
};

export const getWeekForDate = ({ listOfWeeks, date }: {
  listOfWeeks: Array<{
    startDate: Moment,
    endDate: Moment,
  }>,
  date: Moment
}) => listOfWeeks.find(
  (week) => moment(date, DAY_FORMAT)
    .isBetween(week?.startDate, week?.endDate, 'days', '[]'),
);
export const getWeekIndexForDate = ({ listOfWeeks, date }) => listOfWeeks.indexOf(
  getWeekForDate({ listOfWeeks, date }),
);

export const groupLeavesByDayAndWeek = ({ leaves, listOfWeeks }: {
  leaves: Array<Leaves>,
  listOfWeeks: Array<{
    startDate: Moment,
    endDate: Moment
  }>
}) => chain(leaves)
  .map((leave) => {
    const weekWhenLeaveStarts = getWeekIndexForDate({ listOfWeeks, date: leave.startDate });
    const weekWhenLeaveEnds = getWeekIndexForDate({ listOfWeeks, date: leave.endDate });
    const noOfWeeksForTheLeave = weekWhenLeaveEnds - weekWhenLeaveStarts + 1;

    return chain(noOfWeeksForTheLeave)
      .range()
      .map((number) => {
        const weekStartingDate = moment(leave.startDate, DAY_FORMAT)
          .startOf('week')
          .add(number, 'weeks').startOf('day');

        const currentWeek = getWeekForDate({ listOfWeeks, date: weekStartingDate });

        return ({
          ...leave,
          ...getLeaveMetaWithinAGivenWeek({
            leave,
            // @ts-ignore
            week: currentWeek,
          }),
          _extendsToNextWeek: noOfWeeksForTheLeave > number + 1 && noOfWeeksForTheLeave > 1,
          _extendedFromPrevWeek: number > 0,
          _thisWeek: weekStartingDate.format(DAY_FORMAT),
        });
      })
      .value();
  })
  .flatten()
  .map((l) => ({
    ...l,
    _isHalf: l._totalDays === 0.5,
    _startsSecondHalf: l._startType === START_TYPES[1],
    _spansMultipleDays: l._totalDays > 1
                || (l._startType === START_TYPES[1] && l._totalDays === 1),
    _isOneFullDay: l.startDate === l.endDate
                && l._startType === START_TYPES[0]
                && l._endType === END_TYPES[1],
  }))
  .groupBy((l) => l._thisWeek)
  .thru(assignPositionsToLeaves)
  .thru(splitLeavesIntoDays)
  .value();

export const getMaxNoOfHiddenLeavesOnAWeekday = (leavesInAWeek) => chain(leavesInAWeek)
  .mapValues()
  .reduce((acc, leaves) => Math.max(
    acc, leaves.filter((l) => l._position > MAX_LEAVES_TO_SHOW).length,
  ),
  0).value();

export const getAllWeeksBetweenMonths = ({ earliestMonthToShow, latestMonthToShow }) => {
  const allWeeks: Array<{startDate:Moment, endDate: Moment}> = [];
  const lastWeekToShow = {
    startDate: moment(latestMonthToShow, MONTH_FORMAT).endOf('month').startOf('week'),
    endDate: moment(latestMonthToShow, MONTH_FORMAT).endOf('month'),
  };
  let cursor = moment(earliestMonthToShow, MONTH_FORMAT).startOf('month');
  allWeeks.push({
    startDate: moment(earliestMonthToShow, MONTH_FORMAT).startOf('month'),
    endDate: moment(earliestMonthToShow, MONTH_FORMAT).startOf('month').endOf('week'),
  });
  while (lastWeekToShow.startDate.diff(cursor, 'days') > 7) {
    cursor = cursor.add(1, 'week');
    allWeeks.push({
      startDate: cursor.clone().startOf('week'),
      endDate: cursor.clone().endOf('week'),
    });
  }
  allWeeks.push(lastWeekToShow);
  return allWeeks;
};

// const getGroupedLeavesForWeek = ({ startDate, endDate, groupedLeavesByDay }) => {
//   const daysInTheWeek = range(moment(endDate).diff(moment(startDate), 'days') + 1)
//     .map((num) => moment(startDate).clone().add(num, 'days').format(DAY_FORMAT));
//   return pick(groupedLeavesByDay, daysInTheWeek);
// };
