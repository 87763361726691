import React from 'react';
import moment from 'moment';
import { orderBy, get, findIndex } from 'lodash';
import { getFormattedLeavesForListCalendar } from './utils';
import { PauseListCalendarHeader } from '../PauseListCalendarHeader';
import { PausePersonWithAllowance } from '../../PausePersonWithAllowance';
import { PauseListCalendarLeave, PauseListCalendarLeaveWrapper } from '../PauseListCalendarLeave';
import { LIST_CALENDAR_DAY_GAP } from './constants';
import { PauseSidebarCommonComponents } from '../../../composites/PauseFullCalendarSidebar';
import { LEAVE_MARKERS } from '../PauseFullCalendar/constants';
import { PauseFullCalendarLeave, Person } from '../PauseFullCalendarLeave';
import { getLengthOfALeave } from '../PauseFullCalendar/utils';
import { Leaves, PublicHolidays } from '../PauseFullCalendar';

const { START_TYPES, END_TYPES } = LEAVE_MARKERS;

const MAIN_GRID_COLUMNS = '300px 1fr';
export const getCalendarMainGridStyles = (theme) => ({
  display: 'grid',
  alignItems: 'start',
  gridTemplateColumns: MAIN_GRID_COLUMNS,
  gridColumnGap: theme.spacing(5),
  gridRowGap: theme.spacing(3),
  justifyContent: 'start',
});

type Props = {
  startDate: moment.Moment,
  leaves: Array<Leaves>,
  view: any,
  viewCount: any,
  onCalendarViewChange: (any?) => any,
  applyLink: {
    pathname: string,
    query:{
      journey: string,
      stop: string
    }
  },
  selectedPeople: Array<Person>,
  onChangeStartDate: (any?) => any,
  leaveComponentType: string,
  hideSidebar?: boolean,
  hideSwitcher?: boolean,
  publicHolidays: PublicHolidays | Array<any>,
  nonWorkingDays: Array<number>,
  currentCycleStartDate: moment.Moment,
  currentCycleEndDate: moment.Moment

}

export const PauseListCalendar = React.memo<Props>(({
  startDate = moment().startOf('month'),
  leaves,
  view = 'month',
  viewCount = 1,
  onCalendarViewChange,
  applyLink,
  selectedPeople,
  onChangeStartDate,
  leaveComponentType = 'list', // or 'full'
  hideSidebar,
  hideSwitcher,
  publicHolidays = [],
  nonWorkingDays = [1, 7],
  currentCycleStartDate = moment(),
  currentCycleEndDate = moment().add(1, 'year').subtract(1, 'day'),
}) => {
  const endDate = startDate.clone().add(viewCount, view).subtract(1, 'day');

  const formattedLeaves = getFormattedLeavesForListCalendar(
    { startDate, endDate, leaves },
  );

  const selectedPeopleWithLeaves = orderBy(selectedPeople.filter(
    (p) => findIndex(
      formattedLeaves, (l) => get(l, 'person.id') === p.id,
    ) > -1,
  ), (p) => findIndex(
    formattedLeaves, (l) => get(l, 'person.id') === p.id,
  ));

  const selectedPeopleWithoutLeaves = selectedPeople.filter(
    (p) => findIndex(
      formattedLeaves, (l) => get(l, 'person.id') === p.id,
    ) === -1,
  );

  return (
    <>
      <div css={(theme) => ({
        ...getCalendarMainGridStyles(theme),
        ...(hideSidebar && {
          gridTemplateColumns: '1fr',
        }),
      })}
      >
        {!hideSidebar && (
        <div>
          <PauseSidebarCommonComponents />
        </div>
        )}

        <div>
          <PauseListCalendarHeader
            hideSwitcher={hideSwitcher}
            view={view}
            applyLink={applyLink}
            viewCount={viewCount}
            startDate={startDate}
            setStartDate={onChangeStartDate}
            endDate={endDate}
            noOfPeopleToShow={!hideSidebar
              ? selectedPeople.length : selectedPeopleWithLeaves.length}
            // @ts-ignore
            publicHolidays={publicHolidays}
            nonWorkingDays={nonWorkingDays}
            onCalendarViewChange={onCalendarViewChange}
            currentCycleEndDate={currentCycleEndDate}
            currentCycleStartDate={currentCycleStartDate}
          />
        </div>
        {formattedLeaves.map((leavesForAPerson) => {
          const { person, leaves } = leavesForAPerson;
          return (
            <React.Fragment key={`${person.id}`}>
              {!hideSidebar && (
              <PausePersonWithAllowance
                allowance={person.allowance}
                color={person.color}
                teamName={get(person, 'teamName')}
                fullName={person.fullName}
                image={person.image}
                id={person.id}
                // hideAllowance={person?.hideAllowance}
              />
              )}
              <div css={(theme) => ({
                display: 'grid',
                alignSelf: 'center',
                gridTemplateColumns: `repeat(${endDate.diff(startDate, 'days') + 1}, 1fr)`,
                alignItems: 'center',
                gridGap: theme.get(LIST_CALENDAR_DAY_GAP),
              })}
              >
                {leaves.map((leave) => {
                  const lengthOfLeave = getLengthOfALeave({
                    startDate: leave._startDate,
                    endDate: leave._endDate,
                    endType: leave._endType,
                    startType: leave._startType,
                  });
                  return (
                    <PauseListCalendarLeaveWrapper
                      key={leave.id}
                      startDate={leave._startDate}
                      endDate={leave._endDate}
                      viewStartDate={startDate}
                      // startType={leave._startType}
                      // @ts-ignore
                      endType={leave._endType}
                    >
                      {leaveComponentType === 'full' ? (
                        <div css={() => ({
                          paddingLeft: '1px',
                          ...(lengthOfLeave > 1 && leave._startType === START_TYPES[1] && {
                            marginLeft: `calc(50% / ${Math.ceil(lengthOfLeave)})`,
                          }),
                          ...(lengthOfLeave > 1 && leave._endType === END_TYPES[0] && {
                            marginRight: `calc(50% / ${Math.ceil(lengthOfLeave)})`,
                          }),
                        })}
                        >
                          <PauseFullCalendarLeave
                            hideAvatar
                            isHalf={lengthOfLeave === 0.5}
                            startsSecondHalf={leave._startType === START_TYPES[1]}
                            // href={leave.href}
                            // isCutOffOnRight={leave._endType === END_TYPES[0]}
                            // isCutOffOnLeft={leave._startType === START_TYPES[1]}
                            person={person}
                            // viewStartDate={startDate}
                            // startDate={leave._startDate}
                            // endDate={leave._endDate}
                            // startType={leave._startType}
                            // endType={leave._endType}
                          />
                        </div>
                      ) : (
                        <PauseListCalendarLeave
                          href={leave.href}
                          person={person}
                            // @ts-ignore
                          viewStartDate={startDate}
                          startDate={leave._startDate}
                          endDate={leave._endDate}
                          startType={leave._startType}
                          endType={leave._endType}
                        />
                      )}
                    </PauseListCalendarLeaveWrapper>
                  );
                })}
              </div>
            </React.Fragment>
          );
        })}
        {!hideSidebar && selectedPeopleWithoutLeaves.map((person) => (
          <React.Fragment key={person.id}>
            <PausePersonWithAllowance
              allowance={person.allowance}
              color={person.color}
              teamName={get(person, 'teamName')}
              fullName={person.fullName}
              // @ts-ignore
              image={person.image}
              id={person.id}
            />
            <div />
          </React.Fragment>
        ))}
      </div>
    </>
  );
});
