import React from 'react';
import { range } from 'lodash';
import moment, { Moment } from 'moment';
import { darken, lighten } from 'polished';
import Link from 'next/link';
import { PauseTypography } from '../../PauseTypography';
import { DAY_FORMAT, LEAVE_MARKERS } from '../PauseFullCalendar/constants';
import { LIST_CALENDAR_MIN_DAY_WIDTH } from '../PauseListCalendar/constants';
import { Person } from '../PauseFullCalendarLeave';

const { START_TYPES, END_TYPES } = LEAVE_MARKERS;

type PauseListCalendarLeaveWrapperProps = {
  children: React.ReactNode,
  startDate: Moment,
  viewStartDate: Moment,
  endDate: Moment,
}

export const PauseListCalendarLeaveWrapper = ({
  children,
  startDate,
  viewStartDate,
  endDate,
}: PauseListCalendarLeaveWrapperProps) => {
  const offset = moment(startDate, DAY_FORMAT).diff(viewStartDate, 'days');
  const noOfDays = moment(endDate, DAY_FORMAT)
    .diff(moment(startDate, DAY_FORMAT), 'days') + 1;

  return (
    <div css={(theme) => ({
      gridColumn: `${offset + 1} / span 1`,
      gridRow: '1/ span 1',
      width: `calc(${noOfDays} * 100%)`,
      position: 'relative',
      minWidth: theme.spacing(LIST_CALENDAR_MIN_DAY_WIDTH),
      // left: startType === START_TYPES[1] ? `calc(${100 / noOfDays / 2}%)` : '1px',
    })}
    >
      {children}
    </div>
  );
};

type PauseListCalendarLeaveProps = {
  startDate: Moment,
  endDate: Moment,
  startType: string,
  endType: string,
  person:Person | {color: string},
  isCutOffFromLeft?: boolean,
  isCutOffFromRight?: boolean,
  href: string | {
  pathname: string,
    query: {
      id: string,
      journey: string,
      stop: string
  }
},
}

export const PauseListCalendarLeave = ({
  startDate,
  endDate,
  startType,
  endType,
  person = { color: 'primary' },
  isCutOffFromLeft,
  isCutOffFromRight,
  href,
}: PauseListCalendarLeaveProps) => {
  const noOfDays = moment(endDate, DAY_FORMAT)
    .diff(moment(startDate, DAY_FORMAT), 'days') + 1;

  const days = range(noOfDays)
    .map((number) => moment(startDate)
      .clone().add(number, 'days').date());

  return (
    <Link href={href || '/'}>
      <div
        tabIndex={-1}
        css={(theme) => ({
          minWidth: theme.spacing(LIST_CALENDAR_MIN_DAY_WIDTH),
          color: theme.get(`palette.pause.${person.color}.dark`),
          borderRadius: theme.spacing(1),
          overflow: 'hidden',
          position: 'relative',
          // marginLeft: '1px',
          display: 'grid',
          gridAutoFlow: 'column',
          textAlign: 'center',
          height: theme.spacing(4),
          alignItems: 'center',
          // justifyContent: 'center',
          cursor: 'pointer',
          '&:before': {
            content: '""',
            zIndex: 1,
            position: 'absolute',
            top: 0,
            bottom: 0,
            // left: 0,
            // right: 0,
            left: startType === START_TYPES[1] ? `calc(${100 / noOfDays / 2}%)` : '1px',
            right: endType === END_TYPES[0] ? `calc(${100 / noOfDays / 2}%)` : '0px',
            backgroundColor: theme.get(`palette.pause.${person.color}.light`),
          },
          '&:hover': {
            '&:before': {
              backgroundColor: darken('0.02', theme.get(`palette.pause.${person.color}.light`)),
            },
          },
          '&:focus': {
            position: 'relative',
            zIndex: 4,
            outline: 'none',
            boxShadow: `0 0 0 ${theme.spacing(0.25)}px ${lighten('0.2', theme.get(`palette.pause.${person.color}.dark`))}`,
            '&:before': {
              backgroundColor: lighten('0.002', theme.get(`palette.pause.${person.color}.light`)),
            },
          },
          ...(isCutOffFromLeft && {
            borderTopLeftRadius: '0px',
            borderBottomLeftRadius: '0px',
          }),
          ...(isCutOffFromRight && {
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
          }),
        })}
      >
        {days.map((day) => (
          <div
            key={day}
            css={{
              zIndex: 2,
            }}
          >
            <PauseTypography variant="number">
              {day}
            </PauseTypography>
          </div>
        ))}
      </div>
    </Link>
  );
};
