/* eslint no-underscore-dangle: 0 */ // --> OFF
import { range, some, chain } from 'lodash';
import moment from 'moment';
import { lighten } from 'polished';
import React from 'react';
import {
  CALENDAR_BORDER_WIDTH, DAY_FORMAT, MAX_LEAVES_TO_SHOW, MONTH_FORMAT,
} from '../PauseFullCalendar/constants';
import { LeaveType } from '../PauseFullCalendar/utils';
import { PauseFullCalendarDay } from '../PauseFullCalendarDay';

type Props = {
  startDate: moment.Moment,
  endDate: moment.Moment,
  style?: {
    height?: number
    left?: number
    position?: string
    right?: number
    top?: number
    width?: string
  },
  index: number,
  nonWorkingDays?: Array<number>,
  publicHolidays: Array<
    {
    calendarID: string,
    date: string,
    holidayApiID: string,
    id: string,
    name: string,
    orgID: string,
    }
  >,
  birthdays: Array<{
    date: string,
    fullName: string
  }>,
  leaves?: {
    [key: string]: Array<LeaveType & {
      _isMain: boolean,
      _position: number
    }
    >
  },
  currentMonth: string,
  lockedDates?: Array<
    {
      endDate: string
      fullName: string
      reason: string | null
      startDate: string
    }
  >

}

export const PauseFullCalendarWeek = React.memo<Props>(({
  startDate, endDate, style, index, nonWorkingDays = [],
  publicHolidays,
  birthdays,
  leaves = {},
  currentMonth,
  lockedDates = [],
}) => {
  const enforceShowMoreButtonOnAllDays = chain(leaves).mapValues()
    .reduce((acc, leavesArray) => acc || some(leavesArray,
      (l) => l._position > MAX_LEAVES_TO_SHOW), false)
    .value();
  const noOfDaysInTheWeek = endDate.diff(startDate, 'days') + 1;
  const listOfDays = range(noOfDaysInTheWeek).map((num) => moment(startDate).add(num, 'days'));

  return (
    <div
      // @ts-ignore
      css={() => ({
        ...style,
        overflow: 'hidden',
        paddingRight: '6px', // To accommodate the red dot on a leave on the last day of the week
      })}
    >
      <div css={() => ({
        display: 'grid',
        gridTemplateColumns: 'repeat(7, minmax(0, 1fr))',
      })}
      >
        {index === 0 && noOfDaysInTheWeek < 7
              && range(7 - noOfDaysInTheWeek).map(() => <div />)}
        {listOfDays.map((d, dayIndex) => {
          const publicHoliday = publicHolidays.find((ph) => moment(ph.date, DAY_FORMAT).startOf('day').isSame(d.startOf('day')));
          const birthdaysForThisDay = birthdays.filter((bd) => moment(bd.date, DAY_FORMAT).format('MM-DD') === d.format('MM-DD'));
          const lockedDatesForThisDay = lockedDates.filter((ld) => d.isBetween(moment(ld.startDate), moment(ld.endDate), undefined, '[]'));
          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${d.format('YYYY-MM-DD')}-${dayIndex}`}
              css={(theme) => {
                const monthChangeBorderColor = lighten(0.04, theme.get('palette.pause.primary.light'));
                return ({
                  borderTop: `${theme.spacing(CALENDAR_BORDER_WIDTH)}px solid ${theme.get('palette.pause.background.dark')}`,
                  borderLeft: `${theme.spacing(CALENDAR_BORDER_WIDTH)}px solid ${theme.get('palette.pause.background.dark')}`,
                  '&:last-child': {
                    borderRight: `${theme.spacing(CALENDAR_BORDER_WIDTH)}px solid ${theme.get('palette.pause.background.dark')}`,
                  },
                  ...(dayIndex < 6 && {
                    '&:last-child': {
                      borderRight: 'none',
                      boxShadow: `${theme.spacing(CALENDAR_BORDER_WIDTH)}px 0 0 0 ${theme.get('palette.pause.background.dark')}`,
                    },
                  }),
                  ...(d.date() < 8 && {
                    borderTop: `${theme.spacing(CALENDAR_BORDER_WIDTH)}px solid ${monthChangeBorderColor}`,
                  }),
                  ...(d.date() === 1 && {
                    '&:not(:first-child)': {
                      borderLeft: `${theme.spacing(CALENDAR_BORDER_WIDTH)}px solid ${monthChangeBorderColor}`,
                    },
                  }),
                });
              }}
            >
              <PauseFullCalendarDay
                key={d.unix()}
                date={d}
                isNotInCurrentMonth={d.format(MONTH_FORMAT) !== currentMonth}
                birthdays={birthdaysForThisDay}
                publicHoliday={publicHoliday}
                enforceShowMoreButton={enforceShowMoreButtonOnAllDays}
                leaves={leaves[d.format('YYYY-MM-DD')]}
                isActive={moment().startOf('day').isSame(d.startOf('day'))}
                isNonWorkingDay={nonWorkingDays.includes(d.day() + 1) || publicHoliday}
                lockedFor={lockedDatesForThisDay}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
});
