import Link from 'next/link';
import React from 'react';
import { PauseButtonGroup } from '../components/PauseButtonGroup';
import { PauseIconButton } from '../components/PauseIconButton';
import { Calendar, List } from '../../assets/icons/feather/components';
import { PauseButton } from '../components/PauseButton';

export const PauseCalendarSwitcher = ({ viewType, onChangeView, applyLink = '/' }: {
  viewType: string,
  onChangeView: (viewType: string) => any,
  applyLink?: string | {
    pathname: string,
    query: {
      id: string,
      journey: string,
      stop: string
    }
  },
}) => (
  <div css={(theme) => ({
    display: 'grid',
    alignItems: 'center',
    gridAutoFlow: 'column',
    gridGap: theme.spacing(2),
  })}
  >
    <PauseButtonGroup>
      <PauseIconButton
        isActive={viewType === 'full'}
        variant="primaryAltOutlined"
        onClick={() => onChangeView('full')}
        icon={<Calendar />}
      />
      <PauseIconButton
        variant="primaryAltOutlined"
        isActive={viewType === 'list'}
        onClick={() => onChangeView('list')}
        icon={<List />}
      />
    </PauseButtonGroup>
    <Link href={applyLink}>
      <PauseButton>
        Apply
      </PauseButton>
    </Link>
  </div>
);
