import React, { cloneElement } from 'react';
import { isEqual } from 'lodash';

type Props = {
  items: any[],
  children: React.ReactElement,
  styles?: (theme) => ({}),
  onRemove?: (a?:any, b?:any[]) => any,
  [key: string]: any

}

export const PauseAutocompleteSelectedList = ({
  items,
  children,
  styles,
  onRemove,
  ...otherProps
}: Props) => {
  if (!children) { throw new Error('PauseAutocompleteSelectedList expects one child component to render'); }
  const removeItem = (item) => (onRemove !== undefined ? onRemove(item, items.filter((i) => !isEqual(i, item))) : () => null);
  return (
    <div css={(theme) => ({
      display: 'grid',
      gridTemplateRows: '1fr',
      gridTemplateColumns: '1fr',
      gridGap: theme.spacing(3),
      ...(styles && { ...styles(theme) }),
    })}
    >
      {items.map((item, index) => cloneElement(children, {
        key: index,
        onRemove: onRemove && removeItem,
        ...otherProps,
        ...item,
      }))}
    </div>
  );
};
