import React from 'react';
import { Moment } from 'moment';
import { PauseTypography } from '../PauseTypography';
import { smartDateRange } from '../../../utils/datetime';
import { PauseIconButton } from '../PauseIconButton';
import { ChevronLeft, ChevronRight } from '../../../assets/icons/feather/components';

type Props = {
  startDate: Moment,
  endDate: Moment,
  onPrev: (any?) => any,
  onNext: (any?) => any,
  prevDisabled: boolean,
  nextDisabled: boolean
}

export const DateNavigator = ({
  startDate,
  endDate,
  onPrev,
  onNext,
  prevDisabled,
  nextDisabled,
}: Props) => (
  <div css={() => ({
    display: 'flex',
    alignItems: 'center',
  })}
  >
    <PauseTypography
      css={(theme) => ({
        minWidth: '8rem',
        marginRight: theme.spacing(2),
        color: theme.get('palette.pause.primary.main'),
      })}
      variant="title"
    >
      {smartDateRange({ startDate, endDate })}

    </PauseTypography>
    <div css={(theme) => (
      {
        display: 'grid',
        gridAutoFlow: 'column',
        gridGap: theme.spacing(1),
        justifyContent: 'start',
      })}
    >
      <PauseIconButton
        size="small"
        icon={<ChevronLeft />}
        variant="primaryAlt"
        onClick={onPrev}
        disabled={prevDisabled}
      />
      <PauseIconButton
        size="small"
        icon={<ChevronRight />}
        variant="primaryAlt"
        onClick={onNext}
        disabled={nextDisabled}
      />
    </div>
  </div>
);

// DateNavigator.propTypes = Props;
// DateNavigator.defaultProps = defaultProps;
