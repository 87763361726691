import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  get,
  sortBy,
  pick,
  // uniqBy,
} from 'lodash';
import { PauseTypography } from '../components/PauseTypography';
import { PauseAutocompleteSelectedList } from '../components/PauseAutocomplete/PauseAutocompleteSelectedList';
import { PausePersonWithAllowance } from '../components/PausePersonWithAllowance';
import { PauseInput } from '../components/PauseInput';
import {
  Search,
  // Users,
} from '../../assets/icons/feather/components';
import { PauseMenuItem } from '../components/PauseMenuItem';
import { PauseCheckbox } from '../components/PauseCheckbox';
import { PauseAutocomplete2 } from '../components/PauseAutocomplete2';
import { noop } from '../api/_utils';
import { useTeams } from '../api/teams';
import { useMe } from '../api/me';

const Heading = () => (
  <div
    css={(theme) => ({
      display: 'grid',
      alignItems: 'center',
      gridAutoFlow: 'column',
      gridGap: theme.spacing(2),
      gridTemplateColumns: 'auto',
      color: get(theme, 'palette.pause.primary.light'),
      justifyContent: 'start',
    })}
  >
    <PauseTypography variant="header" dontWrapText>
      Your favourites
    </PauseTypography>
  </div>
);

interface SelectedPersonsProps {
  people: any[];
}
const SelectedPersons = ({ people = [] }: SelectedPersonsProps) => (
  // @ts-ignore
  <PauseAutocompleteSelectedList styles={() => ({ overflowY: 'auto' })} items={people}>
    {/* @ts-ignore */}
    <PausePersonWithAllowance />
  </PauseAutocompleteSelectedList>
);

interface CustomLabelProps {
  [x: string]: any;
  label: string;
  value: string;
  selected: boolean;
}

const CustomLabel = React.memo(({ label, value, selected, ...params }: CustomLabelProps) => (
  <PauseMenuItem compact {...params}>
    <div
      css={(theme) => ({
        display: 'flex',
        gap: '16px',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
      })}
    >
      <PauseCheckbox onChange={noop} checked={selected} />
      <div>{label}</div>
    </div>
  </PauseMenuItem>
));

export const PauseSidebarCommonComponents = () => {
  const { teams, loading: loadingTeams } = useTeams();
  const { me, updatePreferences, loading: loadingMe } = useMe();

  const selectedTeamIDs = me?.preferencesObject?.teamIDs || [];

  const teamOptions = useMemo(
    () =>
      (teams || []).map((t) => ({
        label: t.name,
        value: t.id,
      })),
    [teams],
  );

  const [selectedTeams, setSelectedTeams] = useState<any[]>([]);

  const updateSelectedTeams = (option: any[]) => {
    setSelectedTeams(option);
  };

  const memoizedRenderInput = useCallback(
    ({ inputProps, ...params }) => (
      <div ref={params.InputProps.ref}>
        <PauseInput placeholder="Add team to view" leftIcon={<Search />} {...inputProps} {...params} />
      </div>
    ),
    [],
  );

  const memoizedRenderLabel = ({ option, state, ...params }) => (
    <CustomLabel value={option.value} label={option.label} selected={state.selected} {...params} />
  );

  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    if (loadingMe || loadingTeams) return;
    setSelectedTeams(() =>
      teams.filter((t) => selectedTeamIDs.includes(t.id)).map((t) => ({ label: t.name, value: t.id })),
    );
  }, [loadingMe, loadingTeams, selectedTeamIDs, teams]);

  return (
    <div
      css={(theme) => ({
        display: 'grid',
        gridGap: theme.spacing(4),
      })}
    >
      <Heading />
      <PauseAutocomplete2
        disabled={loadingMe || loadingTeams}
        value={selectedTeams}
        onChange={updateSelectedTeams}
        renderInput={memoizedRenderInput}
        disableCloseOnSelect
        renderLabel={memoizedRenderLabel}
        // getOptionLabel={memoizedGetOptionLabel}
        disableHighlighting
        multiple
        options={teamOptions}
        onBlur={() => {
          setInputValue('');
          updatePreferences(selectedTeams.map((s) => s.value));
        }}
        getOptionSelected={(args) => selectedTeams.map((s) => s.value).includes(args.value)}
        inputValue={inputValue}
        onInputChange={(_, nv, reason) => {
          if (reason !== 'reset') setInputValue(nv);
        }}
      />
    </div>
  );
};

interface PauseFullCalendarSidebarProps {
  selectedPeople: any[];
}
export const PauseFullCalendarSidebar = React.memo(({ selectedPeople = [] }: PauseFullCalendarSidebarProps) => (
  <div
    css={(theme) => ({
      display: 'grid',
      height: '100%',
      gridGap: theme.spacing(2.75), // TODO - no idea why this works
      gridTemplateRows: 'auto 1fr',
    })}
  >
    <PauseSidebarCommonComponents />
    <div
      css={() => ({
        position: 'relative',
      })}
    >
      <div
        css={(theme) => ({
          position: 'absolute',
          top: '2px', // TODO - hack to align list view and calendar view
          left: 0,
          bottom: 0,
          right: 0,
          overflowY: 'auto',
          paddingBottom: theme.spacing(3),
        })}
      >
        <SelectedPersons people={selectedPeople} />
      </div>
    </div>
  </div>
));
