import React from 'react';
import { Badge } from '@material-ui/core';
import { darken, lighten } from 'polished';
import moment from 'moment';
import { PauseTypography } from '../../PauseTypography';
import { CALENDAR_DAY_PADDING } from '../PauseFullCalendar/constants';
import { PauseAvatar } from '../../PauseAvatar';
import { ExpressiveColors } from '../../PauseTheme/colors';

export const LEAVE_HEIGHT = 3;

// TODO - don't use darken and lighten for states
// TODO - make Avatar into a component
// TODO - convert into a button

type Props = {
  empty?: boolean,
  needsAction?: boolean,
  alignRight?: boolean,
  isHalf: boolean,
  startsSecondHalf?: boolean,
  isCutOffOnRight?: boolean,
  isCutOffOnLeft?: boolean,
  hideAvatar?: boolean,
  extraPaddingForCutoff?: number
  person: Person
}
export type Person = {
  allowance: number,
  birthDate: moment.Moment,
  color: ExpressiveColors,
  email: string,
  fullName: string,
  id: string,
  image?: string | null,
  isAdmin: boolean,
  isArchived: boolean,
  selected: boolean,
  teamID: string,
  teamName: string,
}
export const PauseFullCalendarLeave = React.memo<Props>(({
  empty,
  person = {},
  needsAction,
  alignRight,
  isHalf,
  startsSecondHalf,
  isCutOffOnRight,
  isCutOffOnLeft,
  hideAvatar = false,
  extraPaddingForCutoff = CALENDAR_DAY_PADDING,
}) => {
  let additionalWidthCount = 0;
  if (isCutOffOnLeft) additionalWidthCount += 1;
  if (isCutOffOnRight) additionalWidthCount += 1;

  return (empty ? (
    <div css={(theme) => ({
      height: theme.spacing(LEAVE_HEIGHT), // computed height of any leave
      opacity: 0,
      visibility: 'hidden',
      pointerEvents: 'none',
    })}
    />
  ) : (
    <Badge
      css={(theme) => ({
        width: '100%',
        '& .MuiBadge-badge': {
          backgroundColor: theme.get('palette.pause.error.main'),
        },
      })}
      invisible={!needsAction}
      variant="dot"
    >
      <div
        title={person.fullName}
        tabIndex={-1}
        css={(theme) => ({
          padding: 0,
          cursor: 'pointer',
          width: '100%',
          borderRadius: theme.spacing(0.75),
          overflow: 'hidden',
          display: 'flex',
          position: 'relative',
          '&:after': {
            content: '""',
            zIndex: 1,
            position: 'absolute',
            top: 0,
            left: isHalf && startsSecondHalf ? '50%' : 0,
            right: isHalf && !startsSecondHalf ? '50%' : 0,
            bottom: 0,
            backgroundColor: theme.get(`palette.pause.${person.color}.light`),
            width: isHalf ? `calc(50% + (${additionalWidthCount} * ${theme.spacing(extraPaddingForCutoff)}px / 2))` : '100%',
          },
          flexDirection: alignRight ? 'row-reverse' : 'row',
          color: theme.get(`palette.pause.${person.color}.dark`),
          '&:hover': {
            '&:after': {
              backgroundColor: darken('0.02', theme.get(`palette.pause.${person.color}.light`)),
            },
          },
          '&:focus': {
            zIndex: 4,
            outline: 'none',
            boxShadow: `0 0 0 ${theme.spacing(0.25)}px ${lighten('0.2', theme.get(`palette.pause.${person.color}.dark`))}`,
            '&:after': {
              backgroundColor: lighten('0.02', theme.get(`palette.pause.${person.color}.light`)),
            },
          },
          ...(isCutOffOnLeft && {
            marginLeft: -theme.spacing(extraPaddingForCutoff),
            paddingLeft: theme.spacing(extraPaddingForCutoff),
            borderTopLeftRadius: '0px',
            borderBottomLeftRadius: '0px',
          }),
          ...(isCutOffOnRight && {
            marginRight: -theme.spacing(extraPaddingForCutoff),
            paddingRight: theme.spacing(extraPaddingForCutoff),
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
          }),
        })}
      >
        {!hideAvatar && person.image && (
        <PauseAvatar
          src={person.image}
          size="small"
            // @ts-ignore
          color={person.color}
        />
        )}
        <PauseTypography
          css={(theme) => ({
            zIndex: 2,
            margin: theme.spacing(0.125),
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          })}
          variant="body1"
          dontWrapText
        >
          {person.fullName}
        </PauseTypography>
      </div
            >
    </Badge>
  ));
});
